.fleet-certificate {
    width: 100vw;
    margin-left: -20px;

    @media (max-width: $screen-md) {
        margin-left: 0;
    }

    .fleet-certificate__container {
        position: relative;
        background-image: linear-gradient(189deg, #191919, #000);
        color: white;
        padding: 50px 0 30px;
        @media (max-width: $screen-md) {
            padding-left: 15px;
            padding-right: 15px;
        }
        .content-wrapper {
            margin-bottom: 50px;
            h1 {
                margin-bottom: 14px;
            }
            p {
                font-size: 16px;
                max-width: 560px;
            }
            @media (max-width: $screen-md) {
                h1 {
                    font-size: 35px;
                    margin-bottom: 22px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
        #fleet-certificate-form {
            display: flex;
            @media (max-width: $screen-md) {
                flex-direction: column;
                #terms-error {
                    top: 40px;
                }
            }
            .left-section .form-element,
            .right-section .form-element {
                margin-bottom: 50px;
                label {
                    color: $silver;
                    position: absolute;
                    bottom: 0.5rem;
                    left: 0;
                    transition: all 0.4s ease-in-out;
                    font-size: 0.7rem;
                    line-height: 1.2rem;
                    font-weight: 500;
                    pointer-events: none;
                    font-family: $secondary-font;
                }
                input,
                textarea,
                select {
                    &:active,
                    &:focus,
                    &.interacted,
                    &[type="date"] {
                        & ~ .label {
                            bottom: 1.5rem;
                        }
                    }
                    & ~ .error {
                        top: 1.7rem;
                        color: $red;
                    }
                }
                input[name="vin"] {
                    text-transform: uppercase;
                }
            }
            .left-section {
                flex: 1 1 50%;
                box-sizing: border-box;
                @media (min-width: $screen-md) {
                    padding-right: 7rem;
                    border-right: 1px solid #4a4a4a;
                }
            }
            .right-section {
                flex: 1 1 50%;
                box-sizing: border-box;
                border-right: 0;
                @media (min-width: $screen-md) {
                    padding-left: 5rem;
                }
                .submit-button {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    gap: 20px;
                    margin-top: 2rem;
                    @media (max-width: $screen-md) {
                        align-items: center;
                        .btn {
                            width: 60%;
                        }
                    }
                }
                .disclaimer {
                    font-family: $secondary-font;
                    display: flex;
                    font-size: 13px;
                    font-weight: 700;
                    color: $silver;
                    line-height: 20px;
                    margin-top: 2rem;
                    a {
                        color: $white;
                        text-decoration: none;
                    }
                    .custom-checkbox {
                        span {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
        .bg-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: 0.6;
        }
    }
}
