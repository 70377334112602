.configurator {
    min-height: 675px;
    @media (max-width: $screen-md) {
        min-height: 755px;
    }
    .breadcrumb-nav{
        height:56px;
    }
    .home {
        .home-container {
            padding: 20px 0 100px;
            @media (max-width: $screen-md) {
                padding: 30px 15px 60px;
                box-sizing: border-box;
            }
            .input-range .input-range__track .input-range__track--active,
            .icon.chevron-input-range {
                background-color: #ee2527;
            }
            .input-range .input-range__track .input-range__slider {
                background-image: url('../images/icons/icon-input-range.svg');
            }

            &.--blue {
                .input-range .input-range__track .input-range__track--active,
                .icon.chevron-input-range {
                    background-color: $blue-hybrid;
                }
                .input-range .input-range__track .input-range__slider {
                    background-image: url('../images/icons/icon-input-range-blue.svg');
                }

            }
        }
        .main-title {
            font-size: 30px;
            width: 100%;
            text-align: center;
            letter-spacing: -0.38px;
            margin-bottom: 45px;
            @media (max-width: $screen-md) {
                margin-bottom: 25px;
                font-size: 25px;
                text-align: left;
            }
        }
        .models-list-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 22px;
            row-gap: 26px;
            position: relative;
            @media (max-width: $screen-md) {
                grid-template-columns: 1fr;
                row-gap: 35px;
            }
        }
        .select-group {
            // max-width: 750px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 60px;
            margin: 0 auto;
            margin-bottom: 60px;
            margin-top: 36px;
            @media (max-width: $screen-md) {
                flex-direction: column;
                max-width: unset;
                margin-bottom: 50px;
                margin-top: 30px;
            }
            .custom-dropdown {
                max-width: 215px;
                @media (max-width: $screen-md) {
                    max-width: unset;
                    margin-top: 18px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
            .custom-checkbox-container {
                width: max-content;
                cursor: pointer;
                border-bottom: 1px solid #d8d8d8;
                padding-bottom: 6px;
                display: flex;
                align-items: center;
                @media (max-width: $screen-md) {
                    width: 100%;
                }

                .checkmark {
                    background-color: $blue-hybrid;
                    border-color: $blue-hybrid;
                    top: 0;
                }

                .checkbox-text {
                    font-size: 14px;
                    font-family: "Nunito", sans-serif;
                    line-height: 23px;
                    letter-spacing: 0.08px;
                }
            }
        }
    }
    .model-detail {
        .model-detail-container {
            display: grid;
            grid-template-columns: 2fr 1.69fr;
            column-gap: 80px;
            @media (max-width: $screen-md) {
                grid-template-columns: 1fr;
                padding: 50px 15px 0;
                box-sizing: border-box;
            }
            .left-section {
                position: relative;
                .car-gallery {
                    position: sticky;
                    top: 75px;
                    width: 100%;
                    padding-bottom: 90px;
                    @media (max-width: $screen-md) {
                        position: relative;
                        padding-bottom: 40px;
                        top: 0;
                    }
                    .car-gallery-container {
                        .gallery-header {
                            position: relative;
                            img {
                                margin-bottom: 5px;
                            }
                            .link-arrow {
                                text-decoration: none;
                                font-size: 13px;
                                line-height: 1.77;
                                letter-spacing: 0.07px;
                                &:hover {
                                    text-decoration: underline;
                                }
                                .icon {
                                    width: 5px;
                                    height: 9px;
                                    margin-left: 8px;
                                }
                            }
                        }
                        .gallery-body {
                            margin-top: -30px;
                            .selected-image {
                                width: 100%;
                            }
                            .gallery-footer {
                                display: flex;
                                .other-images {
                                    opacity: 1;
                                    transition: opacity 400ms;
                                    cursor: pointer;
                                    &:hover, &.active {
                                        opacity: .7;
                                    }
                                    img {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                    .main-features-container {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        row-gap: 16px;
                        max-width: 465px;
                        margin: 40px auto 0;
                        @media (max-width: $screen-md) {
                            max-width: 75%;
                            column-gap: 40px;
                        }
                        .item-feature {
                            display: flex;
                            align-items: center;
                            @media (max-width: $screen-md) {
                                flex-direction: column;
                                align-items: flex-start;
                            }
                            img {
                                margin-right: 12px;
                                max-width: 36px;
                                width: 100%;
                                @media (max-width: $screen-md) {
                                    margin-bottom: 10px;
                                }
                            }
                            span {
                                font-family: $secondary-font;
                                font-size: 14px;
                                font-weight: bold;
                                line-height: 1.43;
                                letter-spacing: 0.08px;
                            }
                        }
                    }
                }
            }
            .right-section {
                padding-bottom: 80px;
                .details {
                    padding-bottom: 32px;
                    border-bottom: 1px solid $silver;
                    @media (max-width: $screen-md) {
                        padding-bottom: 24px;
                    }
                    h1 {
                        color: $darker-black;
                        @media (max-width: $screen-md) {
                            font-size: 28px;
                        }
                    }
                    .version-price {
                        font-size: 34px;
                        margin: 4px 0 24px;

                        @media (max-width: $screen-md) {
                            font-size: 24px;
                        }
                        p {
                            display: flex;
                            gap: 6px;
                            span {
                                font-family: $secondary-font;
                                color: #656566;
                                display: flex;
                                align-items: end;
                                margin-bottom: 8px;
                            }
                        }
                        small {
                            font-size: 12px;
                            display: block;
                        }
                    }

                    .version_warning {
                        font-size: 14px;
                    }

                    .payment-options-button{
                        margin-left: 60px;
                        font-size: 13px;
                        @media (max-width: $screen-md) {
                            padding: 6px 12px;
                        }
                    }
                }
                .bonus-container {
                    display: flex;
                    margin: 15px 0 10px;
                    .bonus-image {
                        margin-right: 12px;
                        width: 48px;
                    }
                    img {
                        width: 100%;
                        min-width: 44px;
                    }
                    p {
                        font-family: $secondary-font;
                        font-size: 15px;
                        line-height: 1.4;
                        small {
                            font-size: 8px;
                            position: relative;
                            bottom: 4px;
                        }
                    }

                    ul {
                        margin: 0;
                        padding: 0;
                        list-style-image: url(../images/icons/tick.svg);
                        list-style-position: outside;
                        margin-left: 15px;

                        li {
                            font-size: 14px;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }

                .paymen-options {
                    .bonus-container {
                        margin-bottom: 15px;
                    }
                    .component-body{
                        .mini-simulator{
                            cursor: unset;
                            small{
                                cursor: pointer;
                            }
                            span{
                                font-size: 18px;
                            }
                            .button-container{
                                text-align: center;
                                margin: 0px 0px 20px 0px;
                                a{
                                    display: block;
                                    padding: 10px;
                                }
                            }
                            .inputs-container{
                                display: flex;
                                flex-direction: column;
                                row-gap: 15px;
                                .input-options-container{
                                    .input-options-body{
                                        display: flex;
                                        column-gap: 10px;
                                        .item-box{
                                            width: 50%;
                                            display: flex;
                                            justify-content: center;
                                            padding: 12px;
                                            &.active{
                                                color: #EE2527;
                                                border: 2px solid #EE2527;
                                            }
                                        }
                                    }
                                }
                                .input-range-container{
                                    h2{
                                        font-size: 23px;
                                    }
                                    p{
                                        font-size: 14px;
                                    }
                                    .input-range-body{
                                        padding-bottom: 50px;
                                    }
                                }
                            }
                            .simulation-result {
                                display: flex;
                                flex-direction: column;
                                padding-top: 16px;

                                .plus {
                                    font-size: 15px;
                                    line-height: 1.6;
                                    font-weight: 400;
                                    color: #000000;
                                }
                                .title {
                                    font-size: 20px;
                                    line-height: 1.4;
                                    font-weight: 600;
                                }
                                .red {
                                    font-size: 15px;
                                    line-height: 1.6;
                                    font-weight: 400;
                                    color: #EE2527;
                                } 
                            }
                        }
                    }
                }
                .version-selector {
                    min-height: unset;
                }
                .colors-container {
                    .component-header {
                        margin-bottom: 10px;
                    }
                    .circle {
                        width: 40px;
                        height: 40px;
                        @media (max-width: $screen-md) {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .active {
                        margin: 3px 5px;
                        border: 2px solid $dark-red;
                    }
                    small {
                        bottom: -15px;
                        font-weight: bold;
                        font-size: 14px;
                        @media (max-width: $screen-md) {
                            bottom: -5px!important;
                            font-size: 12px!important;
                        }
                    }
                    .colors {
                        @media (max-width: $screen-md) {
                            justify-content: flex-start;
                        }
                        button {
                            margin: 14px 4px;
                            @media (max-width: $screen-md) {
                                margin: 14px 0;
                                &.active {
                                    padding: 4px;
                                    margin: 9px 0px;
                                }
                            }
                        }
                    }
                }
                .usado-selector{
                    .component-body{
                        .item-box{
                            span{
                                small{
                                    display: flex;
                                    align-items:center;
                                }
                            }
                            .usado-details{
                                margin-top: 15px;
                                p{
                                    font-size: 20px;
                                    font-weight: 600;
                                    max-width: 290px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis
                                }
                                ul{
                                    font-size: 15px;
                                    li{
                                        font-weight: 600;
                                        &.red-text{
                                            color: $red;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .legals-container {
        .main-container {
            display: flex;
            small {
                font-size: 14px;
                font-family: $secondary-font;
                margin-right: 5px;
            }
        }
        .link-arrow {
            font-size: 14px;
            color: $legals-color;
            .icon {
                width: 4px;
                height: 8px;
                background-color: $legals-color;
            }
        }
    }


    // modal overrides
    .modal {
        .modal-box {
            .modal-header {
                .green-tax {
                    margin-left: 6px;
                    font-family: $secondary-font;
                    font-size: 16px;
                    line-height: 27px;
                }
            }
            .modal-footer {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 15px 0;
            }
        }

        &.modal-financiado {
            .modal-body {
                max-height: 48vh;
            }

            .disclaimer {
                font-size: 12px;
                margin: 15px 0;
                font-weight: 300;
                line-height: 1.5;
            }
        }
    }

    
    .modal-accessory-detail .modal-box .modal-body {
        padding: 30px 75px;
    }

    .modal-accessory-detail{
        .main-container{
            .modal-box{
                .modal-footer{
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: start;
                    padding: 30px 0px;
                    .legals-list-container{
                        margin-bottom: 30px;
                        .text-legal{
                            font-family: Nunito;
                            font-size: 12px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.58;
                            letter-spacing: normal;
                            color: #767676
                        }
                    }
                    .btn-container{
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }

    .model-and-price {
        opacity: 1;
        padding-top: 0;
        padding-bottom: 0;

        .model-and-price-container {
            flex-direction: column;
            justify-content: center;
            height: 51px;
        }

        small {
            color: $silver;
        }
    }

    span.tnc {
        font-size: 14px;
        font-weight: 300;
        margin: 15px 0 0;
        
        a {
            color: $darker-black;
            
        }
    }

    .dealership-selector, .confirmation {

        .model-price-detail {
            border-bottom: 1px solid $silver;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 0;
            margin-bottom: 35px;

            .modelname-and-price {
                font-size: 35px;
                font-weight: 300;

                .price {
                    font-weight: 600;
                }
            }


        }

        .map-header {
            margin: 30px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                max-width: 610px;

                h1 {
                    font-size: 25px;
                }

                h4 {
                    font-size: 16px;
                    font-weight: 300;
                }
            }

            

            .search-box {
                min-width: 400px;

                input {
                    min-width: 345px;
                    float: right;
                }

                ul {
                    max-width: 400px;
                }
            }
        }

        .map-container {
            margin-bottom: 65px;
            position: relative;

            .map-inside {
                min-height: 700px;
                @media (max-width: $screen-md) {
                    min-height: 430px;
                }
            }

            .dealership-modal-container {
                position: absolute;
                top: 0;
                right: 25px;
                @media(max-width:992px) {
                    right: 0;
                }

                .modal-dealership-details {
                    padding: 25px 0;
                }
            }

            .select {
                margin: 15px 0;
                a {
                    display: block;
                }
            }
        }
        
    }

    .confirmation {
        iframe {
            width: 100%;
            min-height: 700px;
            @media (max-width: $screen-md) {
                min-height: 430px;
            }
        }

        .main-container {
            padding-top: 60px;
            @media (max-width: $screen-md) {
                padding-top: 0;
            }
        }
    }

    .config-section {
        font-size: 16px;
        font-weight: 300;
        padding: 15px 0;
        line-height: 1.63;

        &:first-child {
            padding-top: 0;
        }

        &.underlined {
            border-bottom: 1px solid $silver;
        }

        h4 {
            font-size: 20px;
            font-weight: 600;
            margin: 0 0 15px;
        }

        p {
            margin: 1em 0;
        }

        .btn-config-edit {
            display: inline-flex;
            text-align: right;
            justify-content: flex-end;
            padding: 0;
        }
        
        .split {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            *:last-child {
                text-align: right;
            }            

            &.green-tax {
                p {
                    margin: 0;
                }
            }
        }

        .big-number {
            font-family: 'ToyotaType';
            font-size: 29px;
        }

        .list-item {
            padding: 5px 0;
            border-bottom: 1px solid #d8d8d8;

            .item-name {
                font-family: 'ToyotaType';
                font-size: 16px;
            }
        }
    }

    

    sup {
        font-size: 0.5em;
        &.big-sup{
            font-size: 0.8em;
        }
    }

    .card-vehicle {
        margin-bottom: 0;
    }

}

//Generales
.component-header {
    margin-top: 30px;
    margin-bottom: 22px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    @media (max-width: $screen-md) {
        margin-bottom: 18px;
    }
    h2 {
        font-size: 28px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: normal;
        @media (max-width: $screen-md) {
            font-size: 19px;
        }
    }
    a {
        font-family: $secondary-font;
        font-size: 14px;
        text-decoration: none;
        @media (max-width: $screen-md) {
            font-size: 12px;
            line-height: 1.25;
            letter-spacing: 0.07px;
            .icon {
                width: 4px;
                height: 7px;
                position: relative;
                top: 1px;
                margin-left: 5px;
            }
        }   
        &:hover {
            text-decoration: underline;
        }
    }
}

.component-subtitle {
    margin-top: 10px;
    font-family: $secondary-font;
    font-size: 14px;
    flex-wrap: wrap;
    flex-basis: 100%;
}

.component-body {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 12px;
    @media (max-width: $screen-md) {
        row-gap: 8px;
    }
}

.component-legals {
    font-family: $secondary-font;
    font-size: 14px;
    color: $dark-silver;
    margin: 40px 0 20px;
    @media (max-width: $screen-md) {
        margin: 15px 0 20px;
    }
}

.financiamiento-form {
    padding-top: 20px;
    .approve {
        display: flex;
        text-align: start;
        flex-direction: column;
        align-items: start;
        opacity: 0;
        animation: fade 1s linear forwards;

        .fees-details{
            width: 100%;
            opacity: 0;
            animation: fade 1s linear forwards;
            position: relative;
            p{
                font-size: 20px;
                font-weight: 600;
            }
            ul{
                font-size: 15px;
            }
            .details-container{
                .bono-maf{
                    font-size: 15px;
                    color: #EE2527;
                    margin: 0px;
                }
            }
            .btn{
                font-size: 9px;
                cursor: unset;
                background-color: #EE2527;
                color: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                max-height: 20px;
                min-width: unset;
                position: absolute;
                top: 60px;
                right: 0;
                &.evaluando-button{
                    background-color: unset;
                    color: $black;
                }
            }
        }
        &.evaluando{
            text-align: center;
            align-items: center;
        }

        @keyframes fade {
            0% { opacity: 0 }
            100% { opacity: 1 }
        }
    }
    span {
        font-size: 18px;
        margin-bottom: 10px;
    }
    p {
        font-size: 15px;
        font-family: $secondary-font;
        margin-bottom: 10px;
    }
    form {
        cursor: default;
        pointer-events: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 10px;
        .form-element {
            margin: 0;
            input {
                border-bottom: 1px solid $form-grey;
                height: 1.8rem;

                &.error {
                    border-color: $red;
                    color: $red;
                }
            }
            .btn {
                width: 100%;
            }
            &.tick-button{
                .btn{
                    background-color: #EE2527;
                    img{
                        filter: invert(98%) sepia(5%) saturate(331%) hue-rotate(279deg) brightness(118%) contrast(100%);
                        width: 12px
                    }
                }
            }
        }
    }

    .ajax-loader {
        width: 12px;
        height: 12px;
        position: relative;
        top: 2px;
    }
}

.item-box {
    border: 1px solid $silver;
    border-radius: 6px;
    transition: all 300ms;
    cursor: pointer;
    padding: 20px 30px;
    @media (max-width: $screen-md) {
        padding: 12px 20px;
    }
    span {
        font-size: 20px;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        @media (max-width: $screen-md) {
            font-size: 14px;
        }
        small {
            font-family: $secondary-font;
            font-size: 14px;
            @media (max-width: $screen-md) {
                font-size: 12px;
            }
            .icon {
                margin-left: 4px;
                width: 5px;
                height: 9px;
            }
        }
    }
    &.active {
        border: 3px solid $dark-red;
        box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.15);
        pointer-events: none;
        @media (max-width: $screen-md) {
            border: 2px solid $dark-red;
        }
        span {
            font-weight: bold;
            small {
                pointer-events: all;
            }
        }
    }
    &:not(.active) {
        &:hover {
            border: 1px solid $dark-red;
        }
    }
}

.grey-box {
    padding: 40px 0;
    border-radius: 6px;
    background-color: $bg-grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: $screen-md) {
        padding: 32px 0;
    }
    .price {
        margin: 8px 0;
        h1 {
            font-family: $secondary-font;
            font-size: 40px;
            font-weight: normal;
            line-height: 1.2;

            @media (max-width: $screen-md) {
                font-size: 28px;
                margin-bottom: 0;
            }
        }
        .green-tax {
            font-family: $secondary-font;
            font-size: 16px;
            text-align: center;
            color: #656566;
        }
    }
    span {
        font-family: $secondary-font;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 25px;
        @media (max-width: $screen-md) {
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
    .btn {
        min-width: 210px;
    }

}

.summary {
    .hrow {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        a{
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center
        }
    }
    .version_warning {
        font-size: 14px;
        font-weight: 300;
    }
    .grey-box{
        span{
            margin-bottom: 0px;
            text-align: center;
        }
        .divider-line {
            width: 100%;
            margin-bottom: 20px;
        }
        .text-box{
            text-align: center;
            font-size: 14px;
            margin-bottom: 15px;
            padding: 0px 65px;
            span{
                font-weight: 700;
            }
        }
        .summary-link{
            text-decoration: underline;
        }
    }
    .tnc-container{
        text-align: center;
    }
}