.items-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 18px;
    column-gap: 18px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    .item-container {
        padding: 15px;
        box-sizing: border-box;
        border-radius: 6px;
        box-shadow: -7px 0 53px 0 rgba(0, 0, 0, 0.05);
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .icon-container {
            height: max-content;
            img{
                max-height: 75px;
            }
        }
        .details-container {
            height: 55%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title-container {
                h2 {
                    font-size: 18px;
                    font-weight: normal;
                    margin-bottom: 15px;
                }
            }
            .link-container {
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    color: $black;
                    text-decoration: underline;
                    font-weight: bold;
                    i {
                        width: 5px;
                        margin-top: 1px;
                    }
                }
            }
        }
    }

}

@media (min-width: $screen-md) {
    .items-cards-container{
        grid-template-columns: repeat(3, 1fr);
        padding-left: 0;
        padding-right: 0;
        .item-container{
            display: flex;
            align-items: center;
            min-height: 170px;
            flex-direction: row;
            .icon-container{
                height: auto;
                width: 50%;
                img{
                    max-width: 114px
                }
            }
            .details-container{
                width: 50%;
                text-align: initial;
                .title-container{
                    h2{
                        font-size: 24px;
                    }
                }
                .link-container{
                    a{
                        justify-content: start;
                    }
                }

            }
        }
    }
}