.mirai-grey-section {
    padding: 160px 50px;
    background-color: #e6e6e6;
    position: relative;
    overflow: hidden;
    &-container {
        .main-container {
            display: flex;
            flex-direction: column;
            gap: 32px;
            .text-container {
                h2 {
                    font-size: 56px;
                    line-height: 72px;
                    font-weight: 900;
                    margin-bottom: 40px;
                    @media (max-width: $screen-md) {
                        max-width: 452px;
                        font-size: 48px;
                        line-height: 56px;
                    }
                }
                p {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                    max-width: 840px;
                }

                

                @media (max-width: $screen-xs) {
                    padding: 0 44px;
                }
            }
            .images-container {
                display: flex;
                justify-content: space-between;
                gap: 40px;

                .img-container {
                    img {
                        flex-grow: 1;
                        max-width: 100%;
                        object-fit: contain;
                        border-radius: 8px;
                    }
                }

                @media (max-width: $screen-sm) {
                    flex-direction: column;
                    justify-content: center;
                }
            }

            @media (max-width: $screen-sm) {
                flex-direction: row;
            }
            @media (max-width: $screen-xs) {
                flex-direction: column;
            }
        }
    }
    .shapes {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    @media (max-width: $screen-lg) {
        padding: 120px 50px;
    }
    @media (max-width: $screen-md) {
        padding: 80px;
    }

    @media (max-width: $screen-sm) {
        padding: 120px 44px;
    }

    @media (max-width: $screen-xs) {
        padding: 80px 0px;
    }
}
