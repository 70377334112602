@import '~slick-carousel/slick/slick.scss';
@import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css';

// Variables
@import 'variables';

// Fonts
@import 'fonts';

// General
@import 'base';
@import 'breadcrumb-nav';
@import 'footer';
@import 'header';
@import 'inputs';
@import 'buttons';
@import 'cards';
@import 'modals';
@import 'sibling_nav';
@import 'wagtail';

// Components
@import './components/accessories';
@import './components/accordion-group';
@import './components/banner-card-elevation';
@import './components/banner-short-description';
@import './components/benefits';
@import './components/complaint-form';
@import './components/fleet-certificate';
@import './components/carousel-cars';
@import './components/carousel-photo-and-text';
@import './components/color-selector';
@import './components/color-version-selector';
@import './components/configurator';
@import './components/dealers-map';
@import './components/gallery';
@import './components/header-regular';
@import './components/hero-banner';
@import './components/hero-car-showcase';
@import './components/hero-carousel';
@import './components/illustration-with-text';
@import './components/items-cards-block';
@import './components/items-icons-block';
@import './components/items-links';
@import './components/items-news';
@import './components/list-and-photo';
@import './components/maintenance-plan';
@import './components/models-comparator';
@import './components/news-page';
@import './components/outstanding-car-block';
@import './components/photo-triad';
@import './components/prepaid-maintenance-plans';
@import './components/rich-text';
@import './components/search-page';
@import './components/splitter';
@import './components/title-blocks';
@import './components/version-selector';
@import './components/video';
@import './components/zigzag';
@import './components/layout-video-items-block';
@import './components/legal-block';
@import './components/legal-cta';
@import './components/responsive-image';
@import './components/prepaid-maintenance-plan-selector';
@import './components/resources';
@import './components/hero-showcase-block';
@import './components/green-description-block';
@import './components/title-with-text-and-image-block';
@import './components/text-with-logos-block';
@import './components/banner-with-description-block';
@import './components/banner-short-description-small-block';
@import './components/items-inspectors';
@import './components/small-splitter';

// Mirai
@import './components/mirai-main-hero';
@import './components/mirai-grey-section';
@import './components/mirai-white-section';
@import './components/mirai-black-section';
@import './components/mirai-zigzag-section';