.items-inspectors {
    .main-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 60px;
        padding: 0px 40px;

        .card-inspector {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 220px;

            .image-container {
                border-radius: 50%;
                overflow: hidden;
                position: relative;
                height: 170px;
                width: 170px;
                aspect-ratio: 1/1;
                margin-bottom: 24px;
                box-shadow: 10px 10px 55px 0px #0000001C;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            p {
                text-align: center;

                &.title {
                    font-size: 16px;
                    line-height: 24px;
                }
                &.ruc {
                    margin-top: 6px;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }

        @media (max-width: $screen-md) {
            flex-wrap: nowrap;
            overflow-x: auto;
            justify-content: flex-start;
            padding: 30px 15px;

            .card-inspector {
                height: 266px;
                justify-content: flex-start;

                &:last-child {
                    margin-right: 30px;
                }
            }
        }
    }
}