.items-icons-container{
    column-gap: 60px;
    row-gap: 30px;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .item-container {
        font-size: 16px;
        max-width: 339px;
        .header-item {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            img {
                max-width: 40px;
                max-height: 40px;
                margin-right: 8px;
            }
            h3 {
                font-size: 20px;
                font-weight: 400;
                font-family: $secondary-font;
            }
        }
        .span-text {
            font-size: 16px;
            font-family: $secondary-font;
            line-height: 24px;
            font-weight: 400;
        }
        a {
            display: block;
            color: $black;
            font-weight: bold;
            text-decoration: underline;
            margin-top: 10px;
        }
    }
}
@media (min-width: $screen-md) {
    .items-icons-container{
        row-gap: 60px;
        padding-left: 0;
        padding-right: 0;
    }
}