.hero-banner {
    img {
        border-radius: 15px;
        box-shadow: 10px 20px 42px 0 rgba(55, 55, 55, 0.23);
        width: 100%;
        @media (max-width: $screen-md) {
            width: 100%;
            border-radius: 0;
        }
    }
    .image-desktop {
        display: block;
        @media (max-width: $screen-md) {
            display: none;
        }
    }
    .image-mobile {
        display: none;
        @media (max-width: $screen-md) {
            display: block;
        }
    }
}