.prepaid-maintenance-plans {
    @media (min-width: $screen-md) {
        .table-mobile {
            display: none;
        }
    }
    @media (max-width: $screen-md) {
        .main-container {
            padding: 0 15px;
            box-sizing: border-box;
        }
        .table-desktop {
            display: none;
        }
    }
    
}