.news-tag {
    color: $red;
    text-transform: uppercase;
    font-family: $secondary-font;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    padding: 5px 8px;
    border: 1px solid $red;
    box-shadow: 0 0 8px #00000080;
    background-color: $bg-grey;
    border-radius: 4px;
    position: absolute;
    top: 20px;
    left: 20px;
}

.news-holder-page {
    h1 {
        margin-top: 25px;
        @media (max-width: $screen-md) {
            padding: 0 15px;
        }
    }
    .sibling-nav-container {
        .main-container {
            border-bottom: none;
            padding: 20px 0;
            @media (max-width: $screen-md) {
                padding: 15px 15px;
                border-bottom: 1px solid $silver;
            }

            nav ul li a {
                width: max-content;

                @media (max-width: $screen-md) {
                    width: max-content;
                }
            }
        }
    }
    .highlighted-info-container {
        padding: 30px;
        border-bottom: solid 1px $grey;
        @media (max-width: $screen-md) {
            border-bottom: 0;
            padding: 20px 15px;
        }
        .date {
            margin-bottom: 10px;
            font-size: 16px;
            font-family: $secondary-font;
            letter-spacing: 0.09px;
        }
        .title-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            @media (max-width: $screen-md) {
                flex-direction: column;
                align-items: flex-start;
                .btn {
                    width: 100%;
                    padding: 10px 30px;
                }
            }
            h2 {
                font-size: 30px;
                line-height: 1.3;
                letter-spacing: -0.3px;
                @media (min-width: $screen-md) {
                    width: 50%;
                }
                @media (max-width: $screen-md) {
                    margin-bottom: 25px;
                }
            }
        }
    }
    .news-container {
        padding: 75px 0 40px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 22px;
        row-gap: 64px;
        @media (max-width: $screen-md) {
            grid-template-columns: 1fr;
            row-gap: 50px;
            padding: 40px 15px;
        }
        .link-arrow {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.news-page {
    h1 {
        font-size: 55px;
        line-height: 1.22;
        letter-spacing: -0.55px;
        text-align: center;
        margin: 40px 0;
        @media (max-width: $screen-md) {
            font-size: 35px;
            margin: 26px 0;
            text-align: left;
            padding: 0 15px;
        }
    }
    .news-content {
        padding: 70px 0;
        font-family: $secondary-font;
        @media (max-width: $screen-md) {
            padding: 30px 0 70px;
        }
        img {
            width: 100%;
            border-radius: 6px;
        }
    }
}

.corporate-page {
    font-family: $primary-font;
    h1 {
        text-align: left;
        font-size: 40px;
        line-height: 1.13;
        letter-spacing: -0.58px;
        margin-bottom: 30px;
        margin-top: 0;
        @media (max-width: $screen-md) {
            font-size: 30px!important;
            margin: 26px 0;
            text-align: left;
        }
    }
    //Break parent container
    .title-centered-block, .items-illustration-with-text-and-description-block, .items-illustration-with-text-block, .title-with-paragraph-half-width {
        margin-left: -100%;
        margin-right: -100%;
        .main-container {
            max-width: 875px;
        }
    }
    .corporate-content {
        padding: 45px 0;
        @media (max-width: $screen-md) {
            padding: 30px 0 70px;
        }
        img {
            max-width: 100%;
            border-radius: 6px;
            display: block;
            width: auto;
            height: auto;
        }
        .paragraph {
            p {
                font-family: $secondary-font;
            }
            h2 {
                font-size: 25px;
                font-weight: normal;
                @media (max-width: $screen-md) {
                    font-size: 20px;
                }
            }
        }
    }
}