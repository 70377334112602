.accordion-group {
    margin-bottom: 40px;
    .accordion-group-title {
        font-family: $secondary-font;
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 600;
        line-height: 1.44;
        letter-spacing: 0.09px;
    }
}

@media (max-width: $screen-md) {
    .accordion-container {
        padding: 0 15px;
        box-sizing: border-box;
    }
}