.green-description-block {
    @media (max-width: $screen-md) {
        padding: 0 15px;
    }
    h2{
        color: #478929;
        font-weight: 700;
        line-height: 32px;
        font-size: 28px;;
        @media (max-width: $screen-md) {
            font-size: 30px;;
        }
    }
}