.list-and-photo {
    .list-and-photo-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @media (max-width: $screen-md) {
            grid-template-columns: 1fr;
        }
        .left-section {
            h2 {
                margin-bottom: 16px;
                padding: 0 15px;
            }
            .list-items {
                display: flex;
                flex-direction: column;
                max-height: 395px;
                overflow-y: scroll;
                padding: 8px 15px 0 35px;
                margin-left: -35px;
                @media (max-width: $screen-md) {
                    padding: 20px;
                    margin-left: 0;
                    max-height: 322px;
                }
                li {
                    margin-bottom: 12px;
                    background-color: $white;
                    padding: 15px 20px;
                    box-sizing: border-box;
                    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.15);
                    border-radius: 5px;
                    height: 100%;
                    min-height: 120px;
                    position: relative;
                    cursor: pointer;
                    transition: transform 400ms;
                    display: flex;
                    flex-direction: column;
                    @media (min-width: $screen-md) {
                        &:hover {
                            transform: translateX(10px);
                        }
                    }
                    @media (max-width: $screen-md) {
                        min-height: 105px;
                        margin-bottom: 10px;
                        padding: 14px 16px;
                    }
                    small {
                        font-family: $secondary-font;
                        font-size: 14px;
                        line-height: 1.71;
                        color: $form-grey;
                        @media (max-width: $screen-md) {
                            font-size: 12px;
                            margin-bottom: 3px;
                        }
                    }
                    .version-name {
                        font-family: $secondary-font;
                        font-size: 18px;
                        font-weight: 600;
                        @media (max-width: $screen-md) {
                            font-size: 16px;
                        }
                    }
                    .posted-date {
                        font-family: $secondary-font;
                        font-size: 16px;
                        margin-top: auto;
                        @media (max-width: $screen-md) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .right-section {
            padding-left: 28px;
            @media (max-width: $screen-md) {
                display: none;
            }
            img {
                width: 100%;
                border-radius: 5px;
            }
        }
    }
}