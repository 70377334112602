.accessories {
    min-height: 295px;
    position: relative;
    .selects-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        @media (max-width: $screen-md) {
            align-items: center;
        }
    }
    .filter-accessories {
        user-select: none;
        -webkit-user-select: none;
        @media (max-width: $screen-md) {
            padding: 0 15px;
            width: 100%;
            overflow-y: auto;
            box-sizing: border-box;
        }
    }
    .modal-accessory-detail {
        .modal-title {
            .left-title {
                width: 100%!important;
            }
            .price-modal-accessory {
                font-size: 30px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.83;
                position: relative;
                span{
                    font-size: 17px;
                    position: absolute;
                    top:11px;
                }
            }
        }
    }
    .accessories-list-container {
        padding-top: 60px;
        padding-bottom: 50px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 20px;
        row-gap: 50px;
        @media (max-width: $screen-md) {
            grid-template-columns: 1fr;
            row-gap: 40px;
            padding-top: 40px;
            padding-left: 15px;
            padding-right: 15px;
            box-sizing: border-box;
        }
    }
    .modal-footer-accessory{
        flex-direction: column;
        .legals-list-container{
            margin-bottom: 30px;
            .text-legal {
                font-family: Nunito;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.58;
                letter-spacing: normal;
                color: #767676;
            }
        }
        .btn-container {
            width: 100%;
            text-align: center;
        }
    }
}