.carousel-photo-and-text, .carousel-photo-and-text-with-cta {
    background-color: $bg-bonus;
    .main-container {
        padding: 60px 0 10px;
        @media (max-width: $screen-md) {
            padding: 50px 15px 0;
            box-sizing: border-box;
            h1 {
                font-size: 30px;
                line-height: 1.27;
                letter-spacing: -0.53px;
            }
        }
    }
    .mainTitle {
        font-size: 30px;
        margin: 0 auto;
        .titleSlider {
            font-weight: 600;
            .subtitulo {
                font-weight: 300;
            }
        }
        @media (max-width: $screen-md-min) {
            margin: -30px 0px 0px 10px !important;
        }
    }
    .filter {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        @media (max-width: $screen-md) {
            padding-top: 1rem;
            padding-bottom: 1rem;
            margin-right: -15px;
        }
        ul {
            list-style: none;
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            overflow: auto;
            @media (min-width: $screen-sm-min) {
                justify-content: flex-start;
            }
            .slick-list {
                overflow: visible;
                @media (max-width: $screen-xs-max) {
                    padding-right: 7.5rem;
                }
            }
            .slick-list,
            .slick-track {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .slick-arrow {
                display: none !important;
            }
        }
        .list-item {
            margin-left: .5rem;
            margin-right: .5rem;
            text-align: center;
            flex: 0 0 auto;
            @media (max-width: $screen-md) {
                margin-left: .2rem;
                margin-right: .2rem;
            }   
            @media (min-width: $screen-sm-min) {
                width: initial !important
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .btn-filter {
            cursor: pointer;
            text-transform: capitalize;
            font-weight: 500;
            font-size: .7rem;
            line-height: 1.9;
            letter-spacing: .08px;
            border: none;
            padding-top: .25rem;
            padding-bottom: .25rem;
            padding-left: 1rem;
            padding-right: 1rem;
            border-radius: 100px;
            background-color: transparent;
            outline: none;
            transition: background-color .4s ease-in-out;
            &:hover,
            &:focus,
            &:active,
            &.active {
                background-color: $light-grey;
            }
        }
    }
    .slick-slider {
        .slick-arrow {
            opacity: 1;
            top: 0;
            height: 7rem;
        }
    }
    .count {
        text-align: right;
        margin: -60px 145px 30px 0px;
        font-size: 16px;
        font-weight: 300; 
        @media (max-width: $screen-md-min) {
            margin: -30px 5px 30px 10px;
            text-align: left;
        }
    }
    .slider {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            .slick-track {
                margin-left: 0;
                margin-right: 0;
            }
            .slick-list {
                overflow: visible;
            }
        }
        li {
            margin-left: .5rem;
            margin-right: .5rem;
            @media (max-width: $screen-md) {
                margin-right: 0.8rem;
                margin-left: 0;
            }
            img {
                border-radius: 6px;
                width: 100%;
            }
            .title {
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.4;
                margin-top: 1rem;
                margin-bottom: 0;
            }
            .text {
                font-weight: 500;
                font-size: .8rem;
                line-height: 1.75;
                margin-top: 1rem;
                margin-bottom: 0;
            }
        }
        .slick-dots {
            padding-top: 3rem;
            padding-bottom: 3rem;
            @media (max-width: $screen-md) {
                padding-top: 2rem;
                padding-bottom: 2rem;
            }
        }
    }
    @media (max-width: $screen-xs-max) {
        padding-bottom: 30px;
    }
}

.carousel-photo-and-text-with-cta {
    h1 {
        margin-bottom: 40px;
        max-width: 540px;
        line-height: 1.18;
        letter-spacing: -0.8px;
    }
    a {
        color: $black;
        transition: all 400ms!important;
        @media (min-width: $screen-md) {
            &:hover {
                transform: scale(1.05);
            }
        }
        &:not(.slick-active) {
            pointer-events: none;
        }
    }
}