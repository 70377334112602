.header {
    position: fixed;
    top: 0;
    z-index: 4;
    box-sizing: border-box;
    width: 100%;
    background-color: $white;
    display: flex;
    align-items: center;
    height: 3.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    transition: top .4s ease-in-out;
    @media print {
        display: none;
    }
    @media (max-width: $screen-md) {
        padding-left: 0.5rem;
        padding-right: 0;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        @media (max-width: $screen-md) {
            background-color: rgba($black, .1);
        }
        @media (max-width: $screen-xs-max) {
            z-index: 2;
        }
    }
    .backdrop {
        pointer-events: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: $backdrop;
        visibility: hidden;
        opacity: 0;
        transition: all .4s ease-in-out;
        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }
    .header-logo {
        position: relative;
        @media (max-width: $screen-xs-max) {
            z-index: 3;
        }
        img {
            height: 32px;
            @media (max-width: $screen-xs-max) {
                height: 1rem;
            }
        }
    }
    .primary-nav {
        position: relative;
        height: 100%;
        padding-left: 50px;
        @media (max-width: $screen-md-max) {
            display: none;
        }
        ul {
            height: 100%;
            display: flex;
            align-items: center;
            li {
                margin-right: 5px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    text-decoration: none;
                    padding: 6px 15px;
                    border-radius: 40px;
                    height: 100%;
                    font-family: $secondary-font;
                    font-size: 14px;
                    font-weight: 600;
                    color: $black;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all .4s ease-in-out;
                    &:hover, &:focus, &:active, &.active, &.ancestor {
                        box-shadow: 0 0 0px 1px #bbb;
                        &:after {
                            opacity: 1;
                        }
                    }
                    .new{
                        margin-left: 3px;
                        border-radius: 3px;
                        padding: 2px 3px;
                        font-size: 7px;
                        font-family: $secondary-font;
                        background-color: #E30018;
                        color: $white;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    //Menu lateral
    .secondary-nav {
        position: fixed;
        z-index: 2;
        right: 0;
        top: 0;
        bottom: 0;
        min-width: 20rem;
        background-color: $white;
        visibility: hidden;
        opacity: 0;
        transform: translateX(5%);
        transition: all .4s ease-in-out;
        @media (max-width: $screen-xs-max) {
            min-width: auto;
            width: 100%;
        }
        .list {
            position: absolute;
            top: 3.5rem;
            bottom: 0;
            right: 0;
            left: 0;
            overflow-y: auto;
            overflow-x: hidden;
            background-color: $white;
            ul {
                position: relative;
                margin: 0;
                padding: 0;
                padding-top: 1rem;
                padding-bottom: 1rem;
                margin-left: 1.5rem;
                margin-right: 1rem;
                list-style: none;
                text-align: right;
                &:before {
                    content: '';
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    bottom: 0;
                    left: -1.5rem;
                    right: -1rem;
                    background-color: $white;
                }
                &:after {
                    content: '';
                    position: absolute;
                    z-index: 0;
                    top: -1px;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background-color: $light-grey;
                }
                &:first-child {
                    &:after {
                        content: none;
                    }
                }
                li {
                    position: relative;
                    z-index: 1;
                    margin-top: .5rem;
                    margin-bottom: .5rem;
                    margin-right: 1.5rem;
                    &.separator {
                        border-top: 1px solid $light-grey;
                        margin-top: 1.5rem;
                        margin-bottom: 1.5rem;
                        margin-right: 1.5rem;
                    }
                    &.title-header {
                        font-family: $secondary-font;
                        font-size: 15px;
                        font-weight: bold;
                        line-height: 1.87;
                    }
                    a {
                        font-size: 1rem;
                        font-weight: 500;
                        color: $black;
                        text-decoration: none;
                        transition: 400ms all;
                        &:hover {
                            color: $red;
                        }
                        &.image {
                            vertical-align: middle;
                            margin-right: .5rem;
                        }
                    }
                }
            }
        }
    }
    .header-aside {
        display: flex;
        align-items: center;
        position: absolute;
        right: 50px;
        @media (max-width: $screen-md) {
            right: 15px;
        }
        a {
            font-family: $secondary-font;
            font-weight: 600;
            font-size: .7rem;
            color: $black;
            cursor: pointer;
            @media (max-width: $screen-md) {
                display: none;
            }
        }
        .header-toggler {
            font-family: $secondary-font;
            font-weight: 600;
            position: relative;
            z-index: 3;
            cursor: pointer;
            border: none;
            background: none;
            padding: 0;
            margin: 0;
            margin-left: 1.5rem;
            display: flex;
            align-items: center;
            &:hover,
            &:focus,
            &:active {
                outline: none;
            }
            .toggler-text {
                font-size: .7rem;
                color: $black;
                &:before,
                &:after {
                    transition: all .4s ease-in-out;
                }
                &:before {
                    content: attr(data-toggler-closed);
                    visibility: visible;
                    opacity: 1;
                    position: absolute;
                }
                &:after {
                    content: attr(data-toggler-open);
                    visibility: hidden;
                    opacity: 0;
                }
                @media (max-width: $screen-md) {
                    display: none;
                }
            }
            @media (max-width: $screen-md) {
                &.active {
                    .toggler-text {
                        display: block;
                    }
                }
            }
            .toggler-icon {
                width: 20px;
                height: 20px;
                position: relative;
                margin-left: .5rem;
                transform: rotate(0deg);
                transition: .5s ease-in-out;
                span {
                    display: block;
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    background: $black;
                    border-radius: 5px;
                    opacity: 1;
                    left: 0;
                    transform: rotate(0deg);
                    transition: .25s ease-in-out;

                    &:nth-child(1) {
                        top: 3px;
                    }

                    &:nth-child(2) {
                        top: 8.5px;
                        height: 2.5px;
                    }

                    &:nth-child(3) {
                        top: 15px;
                        height: 2px;
                    }
                }
            }
            &.active {
                .toggler-text {
                    &:before {
                        visibility: hidden;
                        opacity: 0;
                    }
    
                    &:after {
                        visibility: visible;
                        opacity: 1;
                    }
                }
                .toggler-icon {
                    span {
                        &:nth-child(1) {
                            top: 8.5px;
                            transform: rotate(135deg);
                        }
    
                        &:nth-child(2) {
                            opacity: 0;
                        }
    
                        &:nth-child(3) {
                            top: 8.5px;
                            transform: rotate(-135deg);
                        }
                    }
                }
            }
        }
    }
    &.open {
        .backdrop {
            visibility: visible;
            opacity: 1;
            pointer-events: all;
        }
        .secondary-nav {
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
            .new{
                margin-left: 3px;
                border-radius: 3px;
                padding: 1px 4px;
                font-size: 8px;
                font-family: $secondary-font;
                background-color: #E30018;
                color: $white;
                font-weight: bold;
                position: relative;
                top: -4px;
            }
        }
    }
}