.mirai-black-section {
    padding: 160px 50px;
    position: relative;
    overflow: hidden;
    background-color: #161616;
    &-container {
        .main-container {
            display: flex;
            gap: 40px;
      
            @media (max-width: $screen-md) {
                flex-direction: column;
            }

            img {
                border-radius: 8px;
                aspect-ratio: auto; 
                width: 50%;
                height: auto;
                object-fit: cover;
                @media (max-width: $screen-md) {
                    width: 100%;
                    aspect-ratio: 16 / 9;
                }
            }
            .text-container {
                min-height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 24px; 
                color: white;

                @media (max-width: $screen-xs) {
                    padding: 0 44px; 
                }

                h2 {
                    font-size: 48px;
                    line-height: 56px;
                    font-weight: 900; 
                }
                p {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                }
            }
        }
    }
    .shapes {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    @media (max-width: $screen-lg) {
        padding: 120px 50px;
    }
    @media (max-width: $screen-md) {
        padding: 80px;
    }
    @media (max-width: $screen-sm) {
        padding: 80px 44px;
    }
    @media (max-width: $screen-xs) {
        padding: 80px 0px;
    }
}
