.benefits {
    .header-benefits {
        // color: white;
        background-position: right;
        .main-container {
            margin-bottom: 76px;
            align-items: center;
            > div {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                max-width: 465px;
                gap: 10px;
                h3 {
                    font-size: 45px;
                }
                p {
                    font-size: 20px;
                    line-height: 27px;
                }
            }
        }
        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }
    .banner-benefits {
        .main-container {
            .banner-benefits__img__desktop {
                max-width: 100%;
                border-radius: 25px;
                box-shadow: 10px 20px 42px 0 rgb(55 55 55 / 23%);
            }
            .banner-benefits__img__mobile {
                width: 100%;
                display: none;
            }
            .banner-benefits__content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 60px 28px 40px 32px;
                border-bottom: 1px solid #cccccc;

                > div {
                    .content__title {
                        font-weight: 600;
                        font-size: 30px;
                        line-height: 40px;
                        color: #333333;
                        margin-bottom: 8px;
                    }
                    .content__subtitle {
                        line-height: 28px;
                        color: #373737;
                        max-width: 414px;
                    }
                }
            }
            @media (max-width: $screen-xs-max) {
                .banner-benefits__img__desktop {
                    display: none;
                }
                .banner-benefits__img__mobile {
                    display: block;
                }
                .banner-benefits__content {
                    flex-direction: column;
                    gap: 26px;
                    padding: 28px 16px 0;
                    border-bottom: none;
                    .content__title {
                        font-size: 28px;
                        line-height: 34px;
                        color: #1e1e1e;
                    }
                    .content__subtitle {
                        font-size: 16px;
                        line-height: 22px;
                        color: #373737;
                    }
                    .btn {
                        width: 100%;
                    }
                }
            }
        }
    }

    .benefits-wrapper {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 56px 30px;
        .benefits-filter {
            .benefits-filter__slider {
                padding: 0 40px;
                @media (max-width: $screen-xs-max) {
                    display: none;
                }
                .slick-slide {
                    opacity: 1;
                }
                .slick-arrow {
                    height: 100%;
                    opacity: 1;
                    visibility: visible !important;
                    top: 20px;
                    width: 30px;
                    transform: translateY(-50%);
                    &.slick-disabled {
                        display: block !important;
                        opacity: 0.4;
                        cursor: default;
                    }
                }
                .filter__item {
                    display: flex !important;
                    align-items: center;
                    gap: 4px;
                    width: fit-content !important;
                    font-size: 14px;
                    line-height: 26px;
                    color: $black;
                    padding: 8px 16px;
                    border-radius: 24.5px;
                    cursor: pointer;
                    &.active {
                        background-color: $bg-bonus;
                    }
                    .filter__close-icon {
                        margin-top: 2px;
                    }
                }
            }
            .custom-dropdown {
                max-width: 100%;
                display: none;
                @media (max-width: $screen-xs-max) {
                    display: block;
                }
            }
        }
        .benefits-container {
            display: grid;
            // grid-template-columns: repeat(4, minmax(0, 1fr));
            grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
            grid-gap: 80px 22px;

            .benefit-card {
                display: flex;
                flex-direction: column;
                height: 100%;

                .benefit-card__img-container {
                    max-height: 118px;
                    min-height: 118px;
                    overflow: hidden;
                    border-radius: 6px;
                    .benefit-card__img {
                        width: 100%;
                    }
                }

                .benefit-card__body {
                    display: flex;
                    flex-direction: column;
                    font-size: 16px;
                    padding: 12px 0 16px;
                    .benefit-card__title {
                        font-weight: 700;
                    }
                    .benefit-card__description {
                        white-space: pre-line;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; /* number of lines to show */
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .benefit-card__category {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 27px;
                        margin-top: 8px;
                    }
                }
                .outline-black {
                    padding: 8px 0;
                }
            }
        }
        @media (max-width: $screen-xs-max) {
            padding: 56px 16px;
            .benefits-container {
                row-gap: 50px;
            }
        }
    }

    .modal-benefit-detail {
        .modal-right {
            .modal-right__img-container {
                max-height: 215px;
                min-height: 215px;
                overflow: hidden;
                border-radius: 6px;
                margin-bottom: 22px;
                img {
                    width: 100%;
                }
                @media (max-width: $screen-md) {
                    min-height: 0;
                }
            }
        }
        .benefit__social-networks {
            display: flex;
            gap: 6px;
            li {
                max-width: 40px;
                max-height: 40px;
            }
        }
        .description {
            white-space: pre-line;
        }

        .modal-footer-benefit {
            display: flex;
            flex-direction: column;

            .legals-list-container {
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding-bottom: 33px;
                .text-legal {
                    font-size: 12px;
                    line-height: 19px;
                    color: #767676;
                }
            }
            @media (max-width: $screen-md) {
                max-width: 100%;
                margin: 0;
                padding: 18px 15px 30px;
            }
            > div {
                width: 100%;
                &.btn-container {
                    display: flex;
                    justify-content: center;
                    gap: 32px;

                    @media (max-width: $screen-xs-max) {
                        gap: 10px;
                    }
                }
            }
        }
    }

    .video {
        position: relative;
        min-height: none;
        padding-top: 24px;

        @media (min-width: $screen-sm) {
            padding-top: 0;
        }
        @media (min-width: $screen-md) {
            min-height: 680px;
        }
        .bg-container {
            position: absolute;
            width: 100%;
            height: 100%;
            @media (max-width: $screen-md) {
                display: none;
            }
            .bg-top, .bg-bottom {
                height: 50%;
            }
            .bg-bottom {
                background-color: $light-grey;
            }
        }
        .video-container {
            @media (min-width: $screen-md) {
                padding: 85px 0;
            }
            iframe {
                width: 100%;
                height: 100%;
                aspect-ratio: 1140/644;
                @media (min-width: $screen-md) {
                    border-radius: 5px;
                }
            }
        }
    }
}
