.small-splitter {
    margin-left: -20px;

    @media (max-width: $screen-md) {
        margin-left: 0;
    }

    .splitter-container {
        background-image: var(--desktop-image);
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 40px;
        @media (max-width: $screen-md) {
            background-image: var(--mobile-image);
            padding: 50px 0 70px;
            min-height: unset;
            .main-container {
                padding: 0 15px;
            }
        }
        .splitter-items-container {
            display: flex;
            justify-content: center;
            align-items: stretch;
            @media (max-width: $screen-md) {
                flex-direction: column;
                align-items: center;
            }
            a {
                color: $white;
                flex-basis: 0;
                flex-grow: 1;
                margin-right: 20px;
                @media (max-width: $screen-md) {
                    margin-right: 0;
                    margin-bottom: 15px;
                    width: 100%;
                }
                &:last-child {
                    margin-right: 0;
                    @media (max-width: $screen-md) {
                        margin-bottom: 0;
                    }
                }
            }
            .splitter-item {
                padding: 33px 30px;
                border: 1px solid $white;
                border-radius: 10px;
                transition: 400ms all;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                position: relative;
                flex-grow: 1;
                justify-content: space-between;
                height: 100%;
                @media (max-width: $screen-md) {
                    min-height: unset;
                    padding: 25px;
                    justify-content: flex-start;
                }
                h3 {
                    max-width: 270px;
                    font-size: 20px;
                    line-height: 1.25;
                    letter-spacing: -0.76px;
                    color: $white;
                    margin-bottom: 8px;
                    @media (max-width: $screen-md) {
                        max-width: 220px;
                    }
                }
                .item-description {
                    transition: 300ms all;
                    max-width: 380px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    p {
                        font-family: $secondary-font;
                        font-size: 14px;
                        line-height: 19px;
                        letter-spacing: -0.2px;
                    }
                    span {
                        font-family: $secondary-font;
                        font-size: 14px;
                        line-height: 19px;
                        letter-spacing: -0.2px;
                        margin-top: 30px;
                        .icon {
                            margin-left: 6px;
                            width: 24px;
                            height: 9px;
                            background-color: $white;
                        }
                    }
                }
            }
        }
    }
}