.title-with-text-and-image{
    @media (max-width: $screen-md) {
        padding: 0 15px;
    }
    .main-container{
        h3{
            font-size: 40px;
            max-width: 460px;
            font-weight: 400;
            line-height: 45px;
            margin-bottom: 35px;
        }
        .description{
            width: 100%;
            p{
                width: 100%;
                font-family: 'Nunito', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                color: #585858
            }
        }
        .content{
            display: flex;
            column-gap: 80px;
            row-gap: 30px;
            @media (max-width: $screen-md) {
                flex-wrap: wrap;
                flex-direction: column;
            }
            .description{
                width: 80%;
                @media (max-width: $screen-md) {
                    width: 100%;
                }
            }
            .image-container{
                width: 710px;
                @media (max-width: $screen-md) {
                    width: 100%;
                }
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 100%;
                }
            }
        }
    }
}