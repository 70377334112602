.items-news {
    .main-container {
        display: flex;
        justify-content: space-between;
        @media (max-width: $screen-md) {
            flex-direction: column;
            padding: 0 15px;
            box-sizing: border-box;
        }
    }
    .card-items-news {
        flex-basis: 0;
        flex-grow: 1;
        padding-bottom: 15px;
        @media (min-width: $screen-md) {
            margin-right: 22px;
            &:last-child {
                margin-right: 0;
            }
        }
        @media (max-width: $screen-md) {
            margin-bottom: 35px;
        }
        .image-container {
            border-radius: 6px;
        }
        img {
            width: 100%;
            max-width: 365px;
            border-radius: 6px;
        }
        p {
            margin: 20px 0 5px;
            font-size: 16px;
            font-family: $secondary-font;
            line-height: 1.44;
            letter-spacing: 0.09px;
        }
        h2 {
            font-size: 25px;
            @media (max-width: $screen-md) {
                font-size: 20px;
            }
        }
    }
}