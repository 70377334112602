.banner-short-description-small {
    margin-top: 50px;
    @media (max-width: $screen-md) {
        margin-top: 0px;
    }
    .banner-short-description-container {
        background-image: var(--image);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        color: $white;
        padding: 25px 0 30px;
        @media (max-width: $screen-md) {
            padding: 40px 30px;
            background-image: unset;
            background-color: #478929;
        }
        .main-container {
            display: flex;
            @media (max-width: $screen-md) {
                flex-direction: column;
                justify-content: center;
                text-align: center;
            }
        }
        .title {
            max-width: 320px;
            border-right: 1px solid $white;
            padding-right: 50px;
            box-sizing: border-box;
            @media (max-width: $screen-md) {
                padding-right: 0;
                border-right: none;
                max-width: unset;
            }
            h1 {
                font-size: 40px;
                line-height: 1.15;
                letter-spacing: -0.7px;
                @media (max-width: $screen-md) {
                    font-size: 30px;
                    margin-bottom: 15px;
                }
            }
        }
        .description {
            padding-left: 50px;
            max-width: 440px;
            display: flex;
            flex-direction: column;
            @media (max-width: $screen-md) {
                max-width: unset;
                align-items: center;
                padding-left: 0;
            }
            p {
                font-family: $secondary-font;
                font-size: 18px;
                margin-bottom: 15px;
                line-height: 1.29;
                @media (max-width: $screen-md) {
                    font-size: 16px;
                    margin-bottom: 25px;
                }
            }
        }
    }
    &.centered {
        .banner-short-description-container {
            .main-container {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
            .title {
                padding: 0;
                max-width: 560px;
                border-right: none;
                margin-bottom: 32px;
            }
            .description {
                padding: 0;
                p {
                    display: none;
                }
            }
        }
    }
}