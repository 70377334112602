.comparator-header {
    background-color: #f7f7f7;
    box-sizing: border-box;
    padding: 2.75rem 0;
    p {
        font-size: .7rem;
        line-height: normal;
        margin-top: .5rem;
        color: #5a5a5a;
    }
    
    h2{
        font-weight: 500;
        line-height: 1.2;
        font-size: 1.25rem;
    }
}

.ego-accordion-title {
    border-bottom:2px solid #d8d8d8;
    padding: .75rem 0;
    h2{
        font-weight: 600;
        line-height: 1.8;
        font-size: .7rem;
    }
}



.ego-accordion-body.expanded {
    border-bottom-color: #d8d8d8;
}

.wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 1100px;
    box-sizing: border-box;
    @media (max-width: 1140px) {
        width:calc(97%);
    }
}

.row {
    display: flex;
}
.rec {
    padding: 0 !important;
    margin: 0 !important;
}
.rec-slider-container{
    overflow: initial !important;
}

.rec-carousel-item{
    div{
        overflow: initial;
        &:focus{
            outline:none
        }
    }
}

