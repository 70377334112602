.search-page {
    .header-regular {
        h1 {
            max-width: unset;
            margin-bottom: 12px;
            @media (max-width: $screen-md) {
                margin-bottom: 25px;
            }
        }
        .main-container {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            @media (max-width: $screen-md) {
                justify-content: flex-end;
                padding-bottom: 34px;
            }
        }
        .help-search-input {
            padding: 8px 45px;
            border: 0;
            outline: none;
            border-radius: 25px;
            min-width: 365px;
            box-sizing: border-box;
            font-family: $secondary-font;
            font-size: 16px;
            letter-spacing: -0.22px;
            color: $dark-silver;
            position: relative;
            @media (max-width: $screen-md) {
                min-width: unset;
                width: 100%;
            }
        }
        form {
            position: relative;
            @media (max-width: $screen-md) {
                width: 100%;
            }
            &:before {
                content: "";
                background-image: url('../images/icons/icon-search.svg');
                width: 21px;
                height: 21px;
                position: absolute;
                left: 13px;
                top: 9px;
                z-index: 1;
            }
        }
        .clear-search {
            -webkit-mask-image: url('../images/icons/modal-close.svg');
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 100%;
            display: none;
            background-color: $black;
            width: 9px;
            height: 9px;
            border: none;
            outline: none;
            padding: 0;
            position: absolute;
            right: 18px;
            top: 14px;
            cursor: pointer;
            &.active {
                display: inline-block;
            }
        }
    }
    .search-container {
        padding-top: 50px;
        h1 {
            font-size: 35px;
            letter-spacing: -0.49px;
        }
        ul {
            display: flex;
            flex-direction: column;
            padding-bottom: 40px;
            border-bottom: 1px solid $bg-light-grey;
            li {
                margin-top: 30px;
                a {
                    color: $black;
                }
                h2 {
                    letter-spacing: -0.35px;
                    margin-bottom: 8px;
                    font-weight: normal;
                }
                p {
                    font-size: 16px;
                    letter-spacing: -0.22px;
                }
            }
        }
    }
}