.mirai-main-hero {
    &-container {
        padding: 80px 50px;
        background-image: var(--desktop_image);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        .main-container {
            display: flex;
            flex-direction: column;
            gap: 25vh;
        }

        .text-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            max-width: 455px;
            &.--white {
              color: $white;
            }
            h5 {
                margin: 0;
                font-size: 24px;
                line-height: 32px;
                font-weight: 900;
            }
            h2 {
                font-size: 56px;
                line-height: 72px;
                font-weight: 900;
            }
            p {
                font-size: 20px;
                line-height: 24px;
                font-weight: 500;
            }
            @media (max-width: $screen-md) {
                h5 {
                    font-size: 16px;
                    line-height: 24px;
                }
                h2 {
                    font-size: 48px;
                    line-height: 56px;
                }
            }
        }

        .features-container {
            &-desktop {
                padding: 24px 40px;
                display: flex;
                justify-content: space-between;
                background: $light-grey;
                border-radius: 8px;
                .features-item {
                    display: flex;
                    max-width: 225px;
                    img {
                        width: 32px;
                        height: 32px;
                    }
                    .content {
                        font-size: 16px;
                        line-height: 24px;
                        &-title {
                            font-weight: 900;
                        }
                        &-text {
                            font-weight: 600;
                        }
                    }
                }
                @media (max-width: $screen-sm) {
                    display: none;
                }
            }

            &-mobile {
                padding: 24px 40px;
                border-radius: 8px;
                background: $light-grey;
                display: none;

                .feature-item {
                    .feature-item-content {
                        display: flex;
                        max-width: 225px;
                        margin: 0 auto;
                        img {
                            width: 32px;
                            height: 32px;
                        }
                        .content {
                            font-size: 16px;
                            line-height: 24px;
                            &-title {
                                font-weight: 900;
                            }
                            &-text {
                                font-weight: 600;
                            }
                        }
                    }
                }
                .slick-slide {
                    opacity: 1;
                }
                .slick-arrow {
                    transform: translateY(25%);
                    &:after {
                        max-height: 12px;
                        max-width: 7px;
                        background-image: url("../images/icons/chevron-strong.svg");
                    }
                    &.slick-prev {
                        transform: translate(-70%, 25%);
                    }
                    &.slick-next {
                        transform: translate(70%, 25%);
                    }
                }
                @media (max-width: $screen-sm) {
                    display: block;
                }
            }
        }

        @media (max-width: $screen-md) {
            padding: 80px;
        }

        @media (max-width: $screen-sm) {
            padding: 40px;
            background-image: var(--mobile_image); 
        }
    }
}
