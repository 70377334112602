.resources-main-content{
    .breadcrumb-nav{
        border-bottom: unset;
    }
}
.resources{
    min-height: 73vh;
    margin-top: 50px;
    .family-selector{
        display: flex;
        flex-direction: column;
        align-items: center;
        .dropdowns{
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            width: 245px;
            margin-top: 20px;
            .custom-dropdown{
                &.disabled{
                    opacity: 0.4;
                    pointer-events: none;
                }
                .selected{
                    .selected-option{
                        margin-left: 0;
                    }
                }
            }
        }
        .btn{
            margin-top: 20px;
        }
    }
    .selected-model-img{
        display: flex;
        justify-content: center;
        margin-top: 40px;
        img{
            width: 670px;
        }
    }
    .resources-options{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .items-illustration-with-text-and-description-block{
        margin-bottom: 100px;
        margin-top: 100px;
    }

    .dashboardlights-container{
        margin-bottom: 100px;
        margin-top: 100px;
        .title{
            font-size: 35px;
        }
        .accordion-container{
            margin-top: 20px;
            padding: 0px 10px;
        }
    }

    .manuals-cards-container {
        background-color: $bg-grey;
        padding: 55px 0;
        position: relative;
        min-height: 560px;
        box-sizing: border-box;
        margin-top: 30px;
        @media (max-width: $screen-md) {
            padding: 35px 15px;
            min-height: 550px;
            .slick-track {
                padding-bottom: 20px;
            }
        }
        &:before {
            content: "";
            border-right: 25px solid transparent;
            border-bottom: 20px solid $bg-grey;
            border-left: 25px solid transparent;
            position: absolute;
            top: -20px;
            left: 50%;
        }
        .slick-list {
            overflow: visible;
        }
        .slick-slide {
            pointer-events: none;
            height: inherit !important;
            & > div {
                height: 100%;
            }
        }
        .slick-active {
            pointer-events: all;
        }

        .slick-track {
            display: flex !important;
        }

        h2 {
            margin-bottom: 35px;
        }

        .card-manual {
            background-color: $white;
            border-radius: 6px;
            cursor: pointer;
            transition: 400ms box-shadow;
            margin-right: 20px;
            min-height: 340px;
            height: 100%;
            &.active {
                box-shadow: 0 0 0 2px $dark-red;
            }
            @media (min-width: $screen-md) {
                &:hover {
                    box-shadow: 0 0 0 2px $dark-red;
                }
            }
            .card-body {
                display: grid;
                grid-template-columns: 1fr;
                row-gap: 10px;
                padding: 25px;
                .card-header {
                    align-items: baseline;
                    small {
                        font-family: $primary-font;
                        font-size: 16px;
                        line-height: 1.69;
                        font-weight: 600;
                    }
                }
                .card-name{
                    height: 200px;
                    h3 {
                        font-weight: normal;
                        line-height: 1.17;
                    }
                }
                .card-footer{
                    display: flex;
                    align-items: center;
                    color: inherit;
                    span{
                        margin-left: 10px;
                    }
                    .icon{
                        margin-top: 4px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .video-section{
        margin-top: 250px;
        @media (max-width: $screen-md) {
            margin-top: 50px;
        }
        .video-container{
            background-color: $bg-grey;
            display: flex;
            justify-content: center;
            max-height: 350px;
            video{
                position: relative;
                bottom: 215px;
                object-fit: fill;
                width: 1150px;
                height: 510px;
                border-radius: 10px;
                @media (max-width: $screen-md) {
                    position: unset;
                    width: 100%;
                    max-height: 220px;;
                    border-radius: 0px;
                }
            }
        }
        
        .videos-cards-container {
            background-color: $white;
            padding: 55px 0;
            position: relative;
            min-height: 560px;
            box-sizing: border-box;
            @media (max-width: $screen-md) {
                padding: 35px 15px;
                min-height: 550px;
                .slick-track {
                    padding-bottom: 20px;
                }
            }
            .slick-list {
                overflow: visible;
            }
            .slick-slide {
                pointer-events: none;
                height: inherit !important;
                & > div {
                    height: 100%;
                }
            }
            .slick-active {
                pointer-events: all;
            }
    
            .slick-track {
                display: flex !important;
            }
    
            h2 {
                margin-bottom: 35px;
                font-size: 40px;
                font-weight: 600;
            }
            .videos-slider{
                margin-top: 20px;
                .card-video {
                    background-color: $white;
                    border-radius: 6px;
                    cursor: pointer;
                    transition: 400ms box-shadow;
                    margin-right: 20px;
                    min-height: 340px;
                    height: 100%;
                    &.active {
                        box-shadow: 0 0 0 2px $dark-red;
                    }
                    .card-body {
                        display: grid;
                        grid-template-columns: 1fr;
                        row-gap: 10px;
                        padding: 5px 15px;
                        color: inherit;
                        .card-header {
                            align-items: baseline;
                            img{
                                width: 100%;
                                border-radius: 5px;
                                object-fit: fill;
                                height: 160px;
                            }
                        }
                        .card-text{
                            height: 200px;
                            margin-top: 10px;
                            h3 {
                                font-weight: normal;
                                line-height: 1.17;
                            }
                            p{
                                margin-top: 15px;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            .categories-container{
                ul{
                    font-size: 14px;
                    list-style: none;
                    padding: 0;
                    margin-top: 0;
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                    overflow: auto;
                    .category-item{
                        cursor: pointer;
                        padding: 5px 15px;
                        margin-left: .2rem;
                        margin-right: .2rem;
                        text-align: center;
                        flex: 0 0 auto;
                        &.active{
                            background-color: $bg-grey;
                            border-radius: 15px;
                        }
                    }
                    @media (max-width: $screen-md) {
                        flex-wrap: nowrap;
                        overflow: auto;
                    }
                }
            }

            .category-slider-mobile{
                cursor: pointer;
                display: none;
                @media (max-width: $screen-md) {
                    display: block;
                }
                .category-item{
                    padding: 5px 15px;
                    &.active{
                        background-color: $bg-grey;
                        border-radius: 15px;
                    }
                }
                .slick-slide {
                    width: unset!important;
                    margin: 0px 20px;
                    height: inherit !important;
                    font-size: 14px;
                    & > div {
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }


}