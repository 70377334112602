html {
    font-size: 20px;
    scroll-behavior: smooth;
    @media (max-width: $screen-md) {
        overflow-x: hidden;
    }
}

body {
    margin: 0;
    overflow: initial;
    overflow-x: hidden;
    min-width: 320px;
    color: $black;
    font-family: $primary-font;
    padding-top: 3.5rem;
}

@media print {
    html, body {
        height:100%; 
        margin: 0 !important; 
        padding: 0 !important;
        overflow: hidden;
    }
}

input, textarea, select, button, p {
    font-family: $secondary-font;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.legal-page {
    ul{
        list-style: initial;
    }
}

a {
    text-decoration: none;
    cursor: pointer;
}

h1 {
    font-size: 40px;
    font-weight: 600;
    margin: 0;
}
h2 {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -0.54px;
    margin: 0;
}
h3 {
    font-size: 23px;
    font-weight: 600;
    margin: 0;
}
h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}
p {
    margin: 0;
}

table, th, td {
    border: 1px solid $black;
    font-family: $secondary-font;
    font-size: 16px;
}

table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    th, td {
        padding: 6px;
    }
    td {
        font-weight: normal;
    }
}

.spinner-container {
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    box-sizing: border-box;
}

.spinner {
	width: 40px;
    height: 40px;
	clear: both;
    margin: 20px auto;
    border: 8px rgba($black,0.15) solid;
	border-top: 8px rgba($red,1) solid;
	border-radius: 50%;
	animation: spinner-rotation .6s infinite linear;
}

@keyframes spinner-rotation {
	from { transform: rotate(0deg); }
	to { transform: rotate(359deg); }
}

.slick-ready {
    .spinner-container {
        display: none;
    }
}

.image-container {
    position: relative;
    overflow: visible;
    &.loaded {
        .placeholder {
            opacity: 0;
            position: absolute;
        }
        .picture {
            opacity: 1;
        }
    }
}
  
.placeholder {
    position: relative;
    width: 100%;
    opacity: 1;
    transition: opacity 400ms linear;
    filter: blur(4px);
}


.picture {
    opacity: 0;
    width: 100%;
    transition: opacity 400ms linear;
}


.pt {
    padding-top: 40px;
    @media (max-width: $screen-md) {
        padding-top: 30px;
    }
}

.pb {
    padding-bottom: 40px;
    @media (max-width: $screen-md) {
        padding-bottom: 30px;
    }
}

.bg_white {
    background-color: $white;
}

.bg_grey {
    background-color: $bg-bonus;
}

.mobile_only {
    @media (min-width: $screen-sm-min) {
        display: none !important;
    }
}

.desktop_only {
    @media (max-width: $screen-md) {
        display: none !important;
    }
}

.break-container {
        margin-left: -100%;
        margin-right: -100%;
}

.main-container {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    position: relative;
    &.large {
        max-width: 1200px;
    }
    &.small {
        max-width: 1040px;
    }
    &.extra-small {
        max-width: 875px;
    }
}

.full-viewport-width {
    width: 100vw;
    @media (max-width: $screen-md) {
        width: auto;
    }
}

.icon {
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100%;
    display: inline-block;
    &.www {
        -webkit-mask-image: url('../images/icons/www.svg');
        background-color: $grey;
        width: 25px;
        height: 26px;
    }
    &.g-maps {
        -webkit-mask-image: url('../images/icons/g-maps.png');
    }
    &.waze {
        -webkit-mask-image: url('../images/icons/waze.png');
    }
    &.location {
        -webkit-mask-image: url('../images/icons/location.svg');
        background-color: $black;
        height: 19px;
        width: 14px;
    }
    &.arrow-right {
        -webkit-mask-image: url('../images/icons/arrow-right.svg');
        background-color: $black;
        height: 11px;
        width: 34px;
    }
    &.chevron-down, &.chevron-right {
        -webkit-mask-image: url('../images/icons/chevron-light-down.svg');
        background-color: $black;
        width: 13px;
        height: 7px;
    }
    &.chevron-right {
        transform: rotate(-90deg);
    }
    &.chevron-strong {
        -webkit-mask-image: url('../images/icons/chevron-strong.svg');
        background-color: $black;
        width: 6px;
        height: 11px;
    }
    &.chevron-input-range {
        -webkit-mask-image: url('../images/icons/chevron-input-range.svg');
        background-color: $red;
        width: 7px;
        height: 14px;
        &.right {
            transform: rotate(180deg);
        }
    }
    &.chevron-tiny-up {
        -webkit-mask-image: url('../images/icons/chevron-tiny.svg');
        background-color: $white;
        width: 9px;
        height: 5px;
    }
    &.icon-360 {
        background-image: url('../images/icons/icon-360.svg');
        width: 63px;
        height: 29px;
    }
    &.close {
        background-image: url('../images/icons/modal-close.svg');
        width: 15px;
        height: 15px;
    }
}

.divider-line {
    hr {
        height: 1px;
        background-color: $light-grey;
        border: none;
    }
}

.default-accordion {
    .accordion-body {
        display: none;
    }
}

.accordion {
    .accordion-title {
        position: relative;
        padding: 15px 0;
        border-bottom: 1px solid $bg-bonus;
        cursor: pointer;
        display: flex;
        align-items: center;
        @media (max-width: $screen-md) {
            padding: 15px 0 10px;
        }
        img{
            width: 36px;
            height: 36px;
            margin-left: 20px;
        }
        h4 {
            font-family: $secondary-font;
            font-size: 18px;
            font-weight: normal;
            padding-left: 22px;
            @media (max-width: $screen-md) {
                font-size: 16px;
            }
        }
        &:before {
            content:"";
            -webkit-mask-image: url('../images/icons/chevron-strong.svg');
            background-color: $black;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 100%;
            width: 8px;
            height: 14px;
            transition: transform 400ms;
            position: relative;
            left: 4px;
        }
    }
    .accordion-body {
        padding: 20px;
        background-color: $bg-bonus;
        font-size: 16px;
        line-height: 1.44;
        letter-spacing: 0.08px;
        font-family: $secondary-font;
    }
    &.active {
        .accordion-title {
            &:before {
                transform: rotate(90deg);
            }
        }
    }
}

.float-social {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: -90px;
    img {
        width: 40px;
        height: 40px;
    }
}

.underline-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 18px;
    border-bottom: 1px solid $silver;
    margin-top: 40px;
    @media (max-width: $screen-md) {
        padding-bottom: 12px;
    }
    h3 {
        font-size: 20px;
        line-height: 1.56;
        @media (max-width: $screen-md) {
            font-size: 18px;
        }
    }
}

.color-red {
    color: $red;
}

.legals-container {
    background-color: $bg-bonus;
    .main-container {
        padding: 30px 0!important;
        @media (max-width: $screen-md) {
            padding: 30px 15px!important;
        }
    }
    .legals-title {
        color: $darker-black;
        font-family: $secondary-font;
        font-size: 14px;
        line-height: 1.71;
        padding-bottom: 8px;
        border-bottom: 1px solid $light-grey;
        margin-bottom: 20px;
    }
    p {
        font-family: $secondary-font;
        color: $legals-color;
        font-size: 12px;
        line-height: 1.33;
        text-align: justify;
        margin-bottom: 12px;
        @media (max-width: $screen-md) {
            font-size: 11px;
            line-height: 1.55;
            letter-spacing: 0.18px;
        }
    }
}

.link-view-more {
    color: $red;
    font-family: $secondary-font;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.92;
    letter-spacing: 0.07px;
    display: flex;
    align-items: center;
    &:after {
        content: "";
        background-image: url('../images/icons/chevron-view-more.svg');
        background-repeat: no-repeat;
        margin-left: 10px;
        width: 9px;
        height: 6px;
    }
}

.paginator {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
    li {
        a {
            &.active {
                pointer-events: none;
            }
            &.btn {
                padding: 7px 12px;
                margin: 0 5px;
            }
        }
    }
}

.responsive-object {
    position: relative;
}
.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.fancybox-slide--image {
    .fancybox-content {
        width: 100% !important;
        height: 100% !important;
        transform: translate(-50%, -50%) !important;
        left: 50%;
        top: 50%;
    }
}

.fancybox-image {
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: $screen-md-max) {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.fancybox-infobar {
    position: absolute;
    display: inline-block;
    top: initial;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(30, 30, 30, 0.9);
    color: $white;
    border-radius: 100px;
    width: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .7rem;
    font-weight: 500;
    mix-blend-mode: initial;
    @media (max-width: $screen-xs-max) {
        left: 3rem;
    }
}

.fancybox-logo {
    pointer-events: none;
    width: 6.5rem;
    height: 1.1rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../images/toyota-white.png');
    position: absolute;
    top: 1rem;
    left: 1.5rem;
    z-index: 99999;
}

.fancybox-caption {
    width: auto;
    display: inline-block;
    left: initial;
    background: none;
    padding-top: 1rem;
    margin-top: 0;
    &__body {
        font-weight: 500;
        font-size: .7rem;
    }
}

.fancybox-toolbar {
    padding-right: 1.25rem;
    padding-top: 1rem;
    @media (max-width: $screen-xs-max) {
        padding-top: .75rem;
        opacity: 1;
        visibility: visible;
    }
}

.fancybox-button {
    border-radius: 100px;
    border: 1px solid $white;
    color: $white;
    &--zoom, &--play {
        display: none;
    }
    &--close {
        width: auto;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 34px;
        width: 195px;
        @media (max-width: $screen-xs-max) {
            width: 160px;
    
        }
        @media (max-width: 400px) {
            width: auto;
            padding-left: .5rem;
            padding-right: .5rem;
        }
        &:before {
            content: 'Volver a la Galeria'
        }
        svg {
            display: none;
        }
    }
}

.fancybox-navigation {
    .fancybox-button{
        padding: 0;
        height: 2.25rem;
        width: 2.25rem;
        margin: 1rem;
        @media (max-width: $screen-xs-max) {
            opacity: 1;
            visibility: visible;
        }
    }
}

.slick-slider {
    &:hover {
        .slick-arrow {
            visibility: visible;
            opacity: 1;
        }
    }
    .slick-slide {
        transition: opacity .4s ease-in-out;
        opacity: 0.5;
        outline: none;
        a {
            outline: none;
        }
        &.slick-active {
            opacity: 1;
        }
    }
    .slick-arrow {
        font-size: 0;
        padding: 0;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        position: absolute;
        bottom: 18px;
        z-index: 1;
        height: 80px;
        width: 3.5rem;
        transition: visibility 0s, opacity 0.5s linear;
        &:after {
            content: '';
            display: block;
            max-height: 2rem;
            max-width: 2rem;
            height: 100%;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            background-image: url('../images/icons/slick-arrow.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        @media (min-width: $screen-sm-min) {
            visibility: hidden;
            opacity: 0;
        }
        &.slick-prev {
            left: 0rem;
            &:after {
                transform: rotate(180deg);
            }
        }
        &.slick-next {
            right: 0rem;
        }
        &.slick-disabled {
            display: none !important;
        }
    }
}

#slider-dots {
    padding-bottom: 10px;
}

.slick-dots {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex!important;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    li {
        line-height: 0;
        margin-left: .5rem;
        margin-right: .5rem;
        &:first-of-type {
            margin-left: 0;
        }
        &:last-of-type {
            margin-right: 0;
        }
        &.slick-active {
            button {
                width: 2rem;
                background-color: darken($silver, 20%);
            }
        }
    }
    button {
        cursor: pointer;
        font-size: 0;
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: 100px;
        background-color: $silver;
        height: .5rem;
        width: .5rem;
        outline: none;
        transition: all .4s ease-in-out;
    }
}

.wagtail-userbar {
    &.wagtail-userbar--bottom-right {
        bottom: 6em;
    }

    &.whatsapp-icon {
        position: fixed;
        z-index: 9999;
        font-size: medium!important;
        line-height: normal;
        margin: 0;
        padding: 0;
        background-color: white;
        border-radius: 50%;
        width: 3.5em;
        height: 3.5em;
        bottom: 2em;
        right: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 1px 0 #02ff17, 0 3px 15px 0 rgba(25, 150, 0, 0.3) !important;
        transition: all .2s ease-in-out;

        img {
            width: 55%;
        }
    }

    &.whatsapp-icon:hover {
        box-shadow: 0 0 1px 0 #02ff17, 0 3px 15px 0 rgba(25, 150, 0, 0.8) !important;
    }
}

.hide-float-buttons .wagtail-userbar{
    display: none;
}