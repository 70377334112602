.form-element {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 1rem;
    input, textarea, select {
        width: 100%;
        background: none;
        outline: none;
        border: none;
        border-bottom: 1px solid $black;
        font-size: .8rem;
        line-height: 1.2rem;
        font-weight: 500;
        transition: all .2s ease-in-out;
        color: $dark-silver;
        box-sizing: border-box;
        height: 1.5rem;
    }
    select {
        appearance: none;
        cursor: pointer;
        option {
            background-color: $darker-black;
        }
    }
    #amount-error {
        top: 2.4rem;
    }
    &.select {
        &:after {
            content: "";
            pointer-events: none;
            position: absolute;
            top: calc(50% - .16667rem);
            right: .3333333333rem;
            display: block;
            height: .3333333333rem;
            width: .3333333333rem;
            border-bottom: 2px solid;
            border-right: 2px solid;
            transform: rotate(45deg) translateY(-50%);
        }
    }
    label {
        position: absolute;
        bottom: .5rem;
        left: 0;
        transition: all .4s ease-in-out;
        font-size: .7rem;
        line-height: 1.2rem;
        font-weight: 500;
        pointer-events: none;
    }
    small {
        font-family: $secondary-font;
        color: $dark-silver;
        letter-spacing: 0.06px!important;
        font-size: 11px!important;
        position: absolute;
        left: 0;
        bottom: -20px;
    }
}

.custom-dropdown {
    width: 100%;
    max-width: 290px;
    position: relative;
    font-size: 16px;
    font-family: $secondary-font;
    line-height: 1.65;
    letter-spacing: -0.23px;
    cursor: pointer;
    label {
        display: none;
    }
    &.with-label {
        label {
            display: block;
            font-family: $secondary-font;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.7;
            letter-spacing: 0.08px;
            position: absolute;
            z-index: -1;
        }
        .selected {
            .selected-option {
                margin-left: auto;
                margin-right: 15px;
                font-size: 14px;
            }
            &:after {
                width: 5px;
                height: 9px;
            }
        }
    }
    &.active {
        .selected {
            &:after {
                transform: rotate(-90deg);
            }
        }
    }
    .selected {
        border-bottom: 1px solid $silver;
        padding-bottom: 6px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:after {
            content: "";
            -webkit-mask-image: url('../images/icons/chevron-strong.svg');
            -webkit-mask-size: 100%;
            background-color: $black;
            width: 7px;
            height: 13px;
            transform: rotate(90deg);
            transition: transform 400ms;
            margin-right: 3px;
        }
    }
    ul {
        background-color: $white;
        border-radius: 0 0 8px 8px;
        box-shadow: 10px 35px 50px 0 rgba(0, 0, 0, 0.1);
        width: 100%;
        position: absolute;
        top: 33px;
        z-index: 2;
        max-height: 300px;
        overflow-y: auto;
        @media (max-width: $screen-md) {
            width: 100%;
        }
        li {
            display: flex;
            flex-direction: column;
            padding: 8px 20px;
            transition: color 400ms;
            font-size: 20px;
            cursor: pointer;
            b {
                font-family: $secondary-font;
                font-size: 16px;
                letter-spacing: -0.24px;
            }
            span {
                font-family: $secondary-font;
                font-size: 14px;
                letter-spacing: -0.2px;
            }
            &:hover {
                color: $red;
            }
            p {
                color: $grey;
                text-align: center;
            }
        }
    }
}

.checkbox-group {
    padding-bottom: 15px;
    .title {
        font-family: $secondary-font;
        font-size: .7rem;
        font-weight: 500;
        display: inline-block;
        width: 100%;
    }
    .detail {
        font-family: $secondary-font;
        color: $dark-silver;
        letter-spacing: 0.06px;
        font-size: 11px;
        position: relative;
        bottom: 5px;
    }
    .group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 20px;
        margin-top: 15px;
        .group-item {
            display: flex;
            font-family: $secondary-font;
            letter-spacing: 0.07px;
            font-size: 14px;
            color: $silver;
        }
    }
}

.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 10px;
    user-select: none;
    .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 12px;
        width: 12px;
        border-radius: 4px;
        border: solid 1px $black;
        background-color: $darker-black;
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 4px;
            top: 1px;
            width: 3px;
            height: 7px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
    .error {
        position: absolute;
        top: 1rem;
        white-space: nowrap;
        font-size: .6rem;
        line-height: 1.2rem;
        color: $red;
        font-weight: 500;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        pointer-events: none;
        &:checked ~ .checkmark {
            background-color: $black;
        }
        &:checked ~ .checkmark:after {
            display: block;
        }
    }
    &:hover input ~ .checkmark {
        background-color: $black;
    }
}

.select-list-filter {
    position: relative;
    @media (max-width: $screen-md) {
        width: 100%;
        border-bottom: 1px solid $grey;
        padding-bottom: 5px;
    }
    .select-title {
        font-family: $secondary-font;
        font-size: 16px;
        line-height: normal;
        letter-spacing: -0.23px;
        cursor: pointer;
        @media (max-width: $screen-md) {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .icon {
            margin-left: 12px;
            transition: 300ms all;
        }
        &.active {
            .icon {
                transform: rotate(180deg);
            }
        }
    }
    ul {
        // display: none;
        padding: 14px 20px 14px 12px;
        border-radius: 5px;
        box-shadow: 0px 0px 25px $light-grey;
        display: flex;
        flex-direction: column;
        background-color: $white;
        z-index: 10;
        position: absolute;
        top: 30px;
        right: -17px;
        @media (min-width: $screen-md) {
            &:before {
                content: '';
                width: 0px;
                height: 0px;
                position: absolute;
                background: transparent;
                border: 10px solid $white;
                top: -20px;
                right: 15px;
                border-right-color: transparent;
                border-left-color: transparent;
                border-top-color: transparent;
            }
        }
        @media (max-width: $screen-md) {
            right: 0;
            top: 23px;
            width: 100%;
            box-sizing: border-box;
            border-radius: 0 0 5px 5px;
        }
        li {
            font-family: $secondary-font;
            font-size: 14px;
            span {
                white-space: nowrap;
                margin-left: 5px;
            }
            div {
                display: flex;
                align-items: center;
                input {
                    cursor: pointer;
                }
            }
        }
    }
}

input {
    &.input-search {
        padding: 8px 10px 8px 40px;
        border: 1px solid $light-grey;
        border-radius: 40px;
        outline: none;
        background-image: url('../images/icons/search.svg');
        background-repeat: no-repeat;
        background-position: 15px 50%;
        @media (max-width: $screen-md) {
            width: 100%;
            box-sizing: border-box;
        }
        &::placeholder {
            color: $grey;
        }
    }
}

.search-box {
    position: relative;
    @media (max-width: $screen-md) {
        width: 100%;
    }
    ul {
        background-color: $white;
        border-radius: 8px;
        box-shadow: 0 4px 13px 1px rgba(0, 0, 0, 0.1);
        width: 120%;
        border: 1px solid $grey;
        position: absolute;
        top: 40px;
        z-index: 2;
        max-height: 300px;
        overflow-y: auto;
        @media (max-width: $screen-md) {
            width: 100%;
        }
        li {
            display: flex;
            flex-direction: column;
            padding: 8px 15px;
            transition: background-color 400ms;
            cursor: pointer;
            b {
                font-family: $secondary-font;
                font-size: 16px;
                letter-spacing: -0.24px;
            }
            span {
                font-family: $secondary-font;
                font-size: 14px;
                letter-spacing: -0.2px;
            }
            &:hover {
                background-color: $light-grey;
            }
            p {
                color: $grey;
                text-align: center;
            }
        }
    }
}

.input-range-container {
    padding-bottom: 20px;
    p {
        font-family: $secondary-font;
        font-size: 18px;
        margin-top: 5px;
        margin-bottom: 25px;
    }
    @media (max-width: $screen-md) {
        padding-bottom: 40px;
        h1 {
            font-size: 22px;
        }
        p {
            margin-top: 8px;
            margin-bottom: 20px;
        }
    }
    .input-range-body {
        background-color: $bg-bonus;
        border-radius: 5px;
        padding: 15px 15px 0px 15px;
        .info {
            display: flex;
            justify-content: space-between;
            padding: 0 12px;
            @media (max-width: $screen-md) {
                padding: 0 0 4px;
            }
            small {
                font-family: $secondary-font;
                font-size: 12px;
                @media (max-width: $screen-md) {
                    font-size: 14px;
                }
            }
            .unit {
                font-family: $secondary-font;
                font-size: 24px;
                letter-spacing: -0.3px;
            }
            .info-left, .info-right {
                display: flex;
                flex-direction: column;
            }
            .info-right {
                text-align: right;
            }
        }
        .input-range {
            display: flex;
            align-items: center;
            .chevron-input-range {
                margin-right: 4px;
                margin-top: 4px;
                cursor: pointer;
                &.right {
                    margin-right: 0;
                    margin-left: 4px;
                }
            }
        }
        &.double {
            .info {
                margin-bottom: 32px;
            }
            .range-wrap {
                .range {
                    left: 0;
                    bottom: 8px;
                    position: absolute;
                    &.min-range {
                        &::-webkit-slider-thumb {
                            z-index: 2;
                            position: relative;
                        }
                        &::-webkit-slider-runnable-track{
                            background: none;
                        }
                    }
                }
            }
        }
    }
}

.range-wrap {
    position: relative;
    margin: 0 auto;
    padding-bottom: 5px;
    width: 100%;
}
.range {
    -webkit-appearance: none;
    width: 100%;
    margin: 0;
    &:focus {
        outline: none;
        &::-webkit-slider-runnable-track {
            background: $red;
        }
    }
    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 8px;
        cursor: pointer;
        animate: 0.2s;
        background: $red;
        border-radius: 4px;
    }
    //Slider icon
    &::-webkit-slider-thumb {
        height: 36px;
        width: 36px;
        border-radius: 50%;
        background-image: url('../images/icons/icon-input-range.svg');
        background-repeat: no-repeat;
        background-position: contain;
        box-shadow: 0 0 8px 0 rgba(107, 107, 107, 0.527);
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -14px;
    }
}

.bubble {
    color: $black;
    position: absolute;
    bottom: -38px;
    left: 50%;
    transform: translateX(-50%);
    font-family: $secondary-font;
    font-size: 18px;
}

button{
    &:disabled{
        background-color: #ccc;
    }
}