.gallery {
    @media (max-width: $screen-md) {
        .main-container {
            padding: 0 15px;
            box-sizing: border-box;
        }
    }
    ul {
        @media (min-width: $screen-sm-min) {
            display: flex;
        }
        li {
            width: 100%;
            flex: 1 1 auto;
            border-radius: 6px;
            height: 21.5rem;
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            transition: .4s ease-in-out background-size;
            &:hover {
                background-size: 110%;
            }
            @media (min-width: $screen-sm-min) {
                margin-left: .5rem;
                margin-right: .5rem;
            }
            @media (max-width: $screen-md-max) {
                height: 17.5rem;
            }
            @media (max-width: $screen-sm-max) {
                height: 13.5rem;
            }
            @media (max-width: $screen-xs-max) {
                background-size: cover;
                height: 17.5rem;
                margin-bottom: 1rem;
            }
            @media (max-width: $screen-xs) {
                height: 13.5rem;
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
                @media (max-width: $screen-xs-max) {
                    margin-bottom: 0;
                }
            }
            .img-box-link {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-decoration: none;
                color: $white;
                text-align: center;
            }
            .hidden {
                display: none;
            }
            a {
                .text {
                    display: block;
                    font-weight: 500;
                    font-size: .8rem;
                    line-height: 1.75;
                    &.highlighted {
                        font-weight: 600;
                        font-size: 2rem;
                        line-height: 1.2;
                        letter-spacing: -.7px;
                    }
                }
            }
        }
    }
}