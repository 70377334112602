// Fonts
$primary-font: "ToyotaType", sans-serif;
$secondary-font: "Nunito", sans-serif;

// Colors
$darker-black: #191919;
$black:#373737;
$grey: #ccc;
$light-grey: #ebebeb;
$silver: #d8d8d8;
$dark-silver: #767676;
$white: #fff;
$red: #eb0a1e;
$dark-red: #b50b1a;
$backdrop: #000000b8;
$legals-color: #585858;
$blue-hybrid: #0069D7;
$middle-blue-hybrid: #CEE6FF;
$light-blue-hybrid: #F5FAFF;
$dark-blue-hybrid: #004489;


$body-bg: #f1f2f4;
$bg-grey: #f6f6f6;
$bg-bonus: #f7f7f7;
$bg-dark-grey: #595959;
$bg-light-grey: #d5d5d5;
$text-color: $black;

$form-grey: #979797;
$form-light-grey: #c4c4c5;

// Breakpoints
$screen-xs: 560px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

$screen-xs-min: $screen-xs;
$screen-sm-min: $screen-sm;
$screen-md-min: $screen-md;
$screen-lg-min: $screen-lg;

$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);