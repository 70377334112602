.dealers-map {
    min-height: 792px;
    @media (max-width: $screen-md) {
        min-height: 720px;
    }
    .map-dealerships-container {
        .map-filters {
            display: flex;
            justify-content: space-between;
            padding: 28px 0;
            @media (max-width: $screen-md) {
                flex-direction: column;
                padding: 40px 15px 30px;
                box-sizing: border-box;
            }
            h2 {
                width: 45%;
                @media (max-width: $screen-md) {
                    width: 100%;
                    font-size: 30px;
                    margin-bottom: 20px;
                }
            }
            .filter-options {
                display: flex;
                width: 55%;
                justify-content: space-between;
                align-items: center;
                @media (max-width: $screen-md) {
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                }
                .current-location {
                    font-size: 16px;
                    line-height: 1.65;
                    letter-spacing: -0.23px;
                    text-decoration: underline;
                    font-family: $secondary-font;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    @media (max-width: $screen-md) {
                        margin-bottom: 15px;
                    }
                    .icon {
                        margin-right: 8px;
                    }
                }
                @media (max-width: $screen-md) {
                    .select-list-filter {
                        margin-bottom: 22px;
                    }
                }
            }
        }
        .map-container {
            min-height: 700px;
            width: 100%;
            position: relative;
            @media (max-width: $screen-md) {
                min-height: 430px;
                position: unset;
            }
            .main-container {
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                pointer-events: none;
            }
            .map-inside {
                min-height: 700px;
                @media (max-width: $screen-md) {
                    min-height: 430px;
                }
            }
            .cards-dealerships-container {
                display: flex;
                overflow-x: auto;
                pointer-events: auto;
                position: absolute;
                width: 100%;
                bottom: 40px;
                .card-body {
                    border-radius: 6px;
                    background-color: $white;
                    min-width: 365px;
                    max-width: 365px;
                    height: 220px;
                    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
                    padding: 25px 30px;
                    box-sizing: border-box;
                    margin-right: 22px;
                    margin-bottom: 28px;
                    position: relative;
                    cursor: pointer;
                    &:last-child {
                        margin-right: 0;
                    }
                    h3 {
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 1.25;
                        letter-spacing: -0.76px;
                        margin: 0;
                        margin-bottom: 20px;
                    }
                    p {
                        font-family: $secondary-font;
                        font-size: 14px;
                        line-height: 1.36;
                        letter-spacing: -0.2px;
                        margin: 0;
                        a {
                            color: $black;
                            &:not(:first-child) {
                                &:before {
                                    content: ", ";
                                }
                            }
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                    .card-footer {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding-left: 30px;
                        padding-bottom: 25px;
                        display: flex;
                    }
                }
            }
        }
    }
}