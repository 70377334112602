//Items cards block
.items-cars-container {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;
    row-gap: 20px;
    @media (max-width: $screen-md) {
        padding: 0 15px;
        box-sizing: border-box;
    }
    @media (min-width: 800px) and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: $screen-md) {
        grid-template-columns: repeat(3, 1fr);
    }
    a:visited{
        color: $black;
    }
}

.card-vehicle {
    margin-bottom: 85px;
    width: 100%;
    position: relative;
    min-height: 205px;
    img {
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;
        position: absolute;
        bottom: -30px;
        @media (max-width: $screen-md) {
            padding: 0 20px;
            width: auto;
            max-height: 100%;
            max-width: 100%;
        }
    }
    .details-car {
        background-color: $bg-bonus;
        min-height: 150px;
        border-radius: 10px;
        padding: 25px 30px;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 1.25;
        letter-spacing: 0.11px;
        color: $black;
        @media (max-width: $screen-md) {
            padding: 25px 20px;
        }
        span {
            font-weight: 500;
        }
        h4 {
            font-size: 20px;
        }
        .title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img {
                padding: 0 0 0 10px;
                position: inherit;
                height: 20px;
                width: auto;
            }
        }
        &.hybrid {
            background-color: $middle-blue-hybrid;
            color: $dark-blue-hybrid;
        }
    }
    p {
        font-family: $secondary-font;
        font-size: 16px;
        margin-top: 60px;
        text-align: center;

        .iva-warning {
            font-size: 14px;
        }
        &.grid-models-price {
            color: $black;
            margin-top: unset;
            position: relative;
            left: 0;
            right: 0;
            bottom: -85px;
        }
        &.green-tax {
            font-family: $secondary-font;
            color: #656566;
            font-size: 12px;
            margin-top: unset;
            position: relative;
            left: 0;
            right: 0;
            bottom: -85px;
        }
    }
    
    &.with-button {
        padding: 5px;
        border-radius: 15px;
        transition: box-shadow 400ms;
        @media (max-width: $screen-md) {
            padding: 0;
        }
        img {
            bottom: 120px;
        }
        .price {
            display: flex;
            flex-direction: column;

            .green-tax {
                font-family: $secondary-font;
                color: #656566;
                font-size: 12px;
                text-align: center;
            }
        }
        .card-footer {
            min-height: 68px;
            padding: 20px 30px;
            opacity: 0;
            transition: opacity 400ms;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            @media (max-width: $screen-md) {
                opacity: 1;
            }
            .btn {
                width: 100%;
                &.limited-width{
                    max-width: 200px;
                }
            }
            &.hybrid {
                .btn {
                    background-color: $blue-hybrid;
                    border-color: $blue-hybrid;
                    &:hover {
                        background-color: $light-blue-hybrid;
                        color: $blue-hybrid;
                    }
                }
            }
        }
        @media (min-width: $screen-md) {
            &:hover {
                box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.1);
                .card-footer {
                    opacity: 1;
                    
                }
            }
        }
    }
}

.card-new {
    display: flex;
    flex-direction: column;
    position: relative;
    h2 {
        font-size: 25px;
        margin-bottom: 30px;
        line-height: 1.17;
        letter-spacing: -0.65px;
        @media (max-width: $screen-md) {
            font-size: 28px;
        }
    }
    img {
        max-height: 240px;
        width: 100%;
        margin-bottom: 22px;
        border-radius: 4px;
    }
    .date {
        margin-bottom: 5px;
        font-size: 16px;
        font-family: $secondary-font;
        letter-spacing: 0.09px;
    }
}

.accessory-card {
    cursor: pointer;
    opacity: 1;
    transition: opacity 400ms;
    @media (min-width: $screen-md) {
        &:hover {
            opacity: .7;
        }
    }
    img {
        border-radius: 6px;
        width: 100%;
        margin-bottom: 10px;
    }
    .price {
        font-family: $secondary-font;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.69;
        position: relative;
        .asterisk{
            padding-left: 1px;
            font-size: 11px;
            position: absolute;
            top:2px;
        }
    }
    p {
        font-family: $secondary-font;
        font-size: 16px;
        line-height: 1.69;
        min-height: 55px;
    }
    .btn {
        margin-top: 12px;
    }
}