.items-links {
    .main-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        @media (max-width: $screen-md) {
            grid-template-columns: 1fr 1fr;
            row-gap: 50px;
            padding: 0 15px;
            box-sizing: border-box;
        }
    }
    .item-link {
        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        img {
            margin-bottom: 15px;
        }
        h3 {
            font-size: 20px;
            font-weight: normal;
            text-align: center;
            line-height: 1.38;
            letter-spacing: -0.29px;
            color: #262a2f;
            max-width: 180px;
        }
        p {
            margin-top: 12px;
            text-align: center;
            font-size: 14px;
            opacity: 0.7;
        }
    }
}