.btn {
    font-family: $secondary-font;
    border-radius: 40px;
    padding: 8px 30px;
    font-size: 14px;
    font-weight: bold;
    background-color: transparent;
    line-height: 1;
    letter-spacing: 0.08px;
    transition: 300ms all;
    white-space: nowrap;
    outline: none;
    transition: 300ms all;
    min-width: 150px;
    color: $darker-black;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    
    @media (min-width: $screen-md) {
        &:hover {
            cursor: pointer;
        }
    }
    &.small {
        padding: 6px 15px;
        font-size: 12px;
    }
    &.block {
        width: 100%;
    }
    &.with-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 30px;
        .icon {
            background-color: $darker-black;
            padding: 10px;
            width: 20px;
            height: 20px;
            box-sizing: border-box;
            margin-right: 10px;
        }
    }
    &.black {
        background-color: $darker-black;
        border: 2px solid $darker-black;
        color: $white;

        &:disabled,[disabled] {
            background-color: $bg-light-grey;
        }

        @media (min-width: $screen-md) {
            &:hover {
                background-color: $white;
                color: $darker-black;
            }
        }
    }
    &.white {
        background-color: $white;
        border: 2px solid $white;
        color: $darker-black;
        @media (min-width: $screen-md) {
            &:hover {
                background-color: $darker-black;
                color: $white;
            }
        }
    }
    &.outline-black {
        border: 2px solid $darker-black;
        @media (min-width: $screen-md) {
            &:hover {
                background-color: $darker-black;
                color: $white;
                &.with-icon {
                    .icon {
                        background-color: $white;
                    }
                }
            }
        }
    }
    &.outline-red {
        border: 2px solid $dark-red;
        color: $dark-red;
        @media (min-width: $screen-md) {
            &:hover {
                background-color: $dark-red;
                color: $white;
                &.with-icon {
                    .icon {
                        background-color: $white;
                    }
                }
            }
        }
    }
    &.outline-white {
        border: 2px solid $white;
        color: $white;
        @media (min-width: $screen-md) {
            &:hover {
                background-color: $white;
                color: $black;
                &.with-icon {
                    .icon {
                        background-color: $black;
                    }
                }
            }
        }
    }
    &.category {
        border: none;
        padding: 12px 30px;
        min-width: unset;
        font-weight: normal;
        transition: background-color 400ms;
        @media (max-width: $screen-md) {
            font-size: 12px;
            padding: 8px 15px;
        }
        &:hover, &.active {
            background-color: $bg-bonus;
        }
    }
    &:disabled,[disabled] {
        color: $form-grey;
        border: 2px solid $form-grey;
        pointer-events: none;
    }
}

.link-arrow {
    font-family: $secondary-font;
    font-size: 15px;
    font-weight: 600;
    color: $black;
    text-decoration: underline;
    display: flex;
    align-items: center;
    .icon {
        margin-left: 10px;
    }
}

.colors {
    display: flex;
    align-items: center;
    min-height: 30px;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media (max-width: $screen-md) {
        justify-content: center;
    }
    .color-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        min-height: 70px;
        position: relative;
    }
    button {
        background-color: transparent;
        border: none;
        outline: none;
        margin: 8px 0px;
        &.active {
            margin: 3px 0;
            padding: 5px;
            width: auto;
            border: 1px solid $dark-red;
            border-radius: 50%;
            + small {
                opacity: 1;
            }
        }
    }
    .circle {
        width: 34px;
        height: 34px;
        display: block;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 0 0 3px $grey;
    }
    small {
        font-family: $secondary-font;
        font-size: 12px;
        opacity: 0;
        transition: opacity 400ms;
        margin-top: 3px;
        white-space: nowrap;
        position: absolute;
        bottom: 0;
    }
}

.arrow-prev, .arrow-next {
    background-image: url('../images/icons/chevron-grey.svg');
    border: none;
    outline: none;
    background-color: transparent;
    height: 19px;
    width: 12px;
    cursor: pointer;
}

.arrow-next {
    transform: rotate(180deg);
}