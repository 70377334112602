.breadcrumb-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    z-index: 1;
    border-bottom: 1px solid rgba(55, 55, 55, 0.1);
    transition: all .4s ease-in-out;
    border-top: 1px solid $silver;
    border-bottom: 1px solid $silver;
    @media (max-width: $screen-sm-max) {
        display: none;
    }
    ul {
        display: flex;
        align-items: center;
        li {
            position: relative;
            margin-left: .75rem;
            margin-right: 2.25rem;
            &:first-child {
                margin-left: 0;
                
                a {
                    font-weight: 600;
                    font-size: .7rem;
                    line-height: 1;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: -1.5rem !important;
                height: .25rem;
                width: .25rem;
                border-top: 1px solid;
                border-right: 1px solid;
                transform: rotate(45deg) translateY(-50%);
            }

            &:last-child {
                margin-right: 0;
    
                &:after {
                    content: none !important;
                }
    
                a {
                    pointer-events: none;
                }
            }
            &:first-child:last-child {
                a {
                    pointer-events: all;
                }
            }
        }
    }
    a {
        display: block;
        font-weight: 500;
        font-size: .7rem;
        line-height: 1;
        color: inherit;
        text-decoration: none;
    }
    .button {
        line-height: 1rem;
    }
    .contact{
        display: flex;
        align-items: center;
        column-gap: 15px;
        p{
            font-size: 14px;
        }
    }
}
.home {
    .breadcrumb-nav {
        display: none;
    }
}
.main {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 52px;
    position: relative;
    @media (max-width: $screen-md) {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }
    .breadcrumb-nav {
        position: fixed;
        width: 100%;
        background-color: $white;
        top: 70px;
        z-index: 3;
    }
}
.model-and-price {
    font-family: $secondary-font;
    font-size: 18px;
    box-sizing: border-box;
    padding: 0.6rem 3rem;
    position: fixed;
    right: 0;
    top: 70px;
    transition: opacity 400ms;
    opacity: 0;
    z-index: 3;
    @media (max-width: $screen-md) {
        position: relative;
        top: 0;
        padding: 0;
        font-size: 20px;
    }
    &.active {
        opacity: 1;
    }
    .model-and-price-container {
        display: inline-flex;
        align-items: center;
        .cotizar-container, .price-and-model {
            display: flex;
            i {
                display: none;
            }

            .price_container {
                display: flex;
                #green-tax {
                    font-size: 12px;
                    color: #656566;
                }
            }


            @media (max-width: $screen-md) {
                width: 100%;
                padding: 15px;
                border-bottom: 1px solid $grey;
                .price_container {
                    flex-direction: column;
                }
            }
        }
        @media (max-width: $screen-md) {
            flex-direction: column;
            align-items: baseline;
            display: flex;
            .price-and-model {
                justify-content: space-between;
                box-sizing: border-box;
                span {
                    margin-left: auto;
                }
            }
            .cotizar-container {
                align-items: center;
                .btn {
                    color: $black;
                    font-size: 15px;
                    background-color: transparent;
                    border: 0;
                    padding: 0;
                    text-align: left;
                    min-width: unset;
                }
                i {
                    display: block;
                    margin-left: 10px;
                }
            }
        }
    }
    strong, small {
        margin-right: 15px;
    }
    small {
        font-size: 12px;
        align-self: end;
    }
}