.mirai-zigzag-section {
    padding: 160px 50px;
    position: relative;
    overflow: hidden;
    &-container {
        h2 {
            margin-bottom: 40px;
            font-size: 56px;
            line-height: 72px;
            font-weight: 900;

            @media (max-width: $screen-md) {
                font-size: 48px;
                line-height: 56px;
            }
            @media (max-width: $screen-xs) {
                padding: 0px 44px;
            }
        }
        .zigzag-container {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .item-container {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 40px;
                align-items: center;

                .zigzag-img {
                    width: 100%;
                }
                &:nth-child(odd) {
                    > div:last-child {
                        order: -1;
                    }
                }

                @media (max-width: $screen-md) {
                    display: flex;
                    flex-direction: column;

                    &:nth-child(even) {
                        > div:last-child {
                            order: -1;
                        }
                    }
                }
                .item-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 24px;
                    
                    @media (max-width: $screen-xs) {
                        padding: 0px 44px;
                    }

                    h5 {
                        font-size: 24px;
                        line-height: 32px;
                        margin: 0;
                    }
                    > div {
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                        p {
                            font-size: 16px;
                            line-height: 24px;
                        }
                        .icon-item {
                            h6 {
                                margin: 0;
                                display: flex;
                                align-items: center;
                                gap: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
    .shapes {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    @media (max-width: $screen-lg) {
        padding: 120px 50px;
    }
    @media (max-width: $screen-md) {
        padding: 80px;
    }
    @media (max-width: $screen-sm) {
        padding: 80px 44px;
    }
    @media (max-width: $screen-xs) {
        padding: 80px 0px;
    }
}
