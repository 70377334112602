.mirai-white-section {
    padding: 160px 50px;
    position: relative;
    overflow: hidden;
    &-container {
        .main-container {
            display: flex;
            flex-direction: column;
            gap: clamp(24px, 5vh, 32px);

            .top-container {
                display: flex;
                gap: 40px;
                img {
                    border-radius: 8px;
                    aspect-ratio: auto;
                    width: 50%;
                    height: auto;
                    object-fit: cover;
                    @media (max-width: $screen-md) {
                        width: 100%;
                        aspect-ratio: 16 / 9;
                    }
                }
                .text-container {
                    min-height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 16px;

                    @media (max-width: $screen-xs) {
                        padding: 0 44px;
                    }

                    h2 {
                        font-size: 56px;
                        line-height: 72px;
                        font-weight: 900;
                        @media (max-width: $screen-md) {
                            font-size: 48px;
                            line-height: 56px;
                        }
                    }
                    p {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 500;
                        max-width: 570px;
                        @media (max-width: $screen-md) {
                            font-size: 13px;
                            line-height: 16px;
                            font-weight: 700;
                        }
                    }
                }

                @media (max-width: $screen-md) {
                    flex-direction: column;
                }
            }

            .items-container {
                display: flex;
                justify-content: space-between;
                gap: clamp(24px, 5vw, 72px);

                @media (max-width: $screen-xs) {
                    flex-direction: column;
                    gap: 24px;
                    padding: 0 44px;
                }

                .item-container {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    flex-basis: 0;
                    flex-grow: 1;
                    max-width: 352px;
                    h6 {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        margin: 0;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 700;
                        font-family: $secondary-font;
                    }
                    p {
                        font-size: 16px;
                        line-height: 24px;
                        @media (max-width: $screen-lg) {
                            font-size: 13px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }
    .shapes {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    @media (max-width: $screen-lg) {
        padding: 120px 50px;
    }
    @media (max-width: $screen-md) {
        padding: 80px;
    }
    @media (max-width: $screen-sm) {
        padding: 80px 0px;
    }
}
