.title-centered-block {
    @media (max-width: $screen-md) {
        padding-left: 15px;
        padding-right: 15px;
    }
    .main-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: $screen-md) {
            align-items: flex-start;
            padding: 0 15px;
        }
    }
    .subtitle {
        font-family: $secondary-font;
        font-size: 16px;
        line-height: 1.44;
        letter-spacing: 0.09px;
        color: $darker-black;
    }
    h2 {
        line-height: 50px;
        letter-spacing: -0.58px;
        margin-top: 15px;
        font-size: 40px;
        font-family: $primary-font;
        font-weight: 600;
        @media (max-width: $screen-md) {
            font-size: 30px;
            margin-top: 5px;
        }
    }
    .subtitle, h2 {
        text-align: center;
        text-wrap-style: balance;
        @media (max-width: $screen-md) {
            text-align: left;
        }
    }
}

.title-left-block {
    .main-container {
        align-items: baseline;

        @media (max-width: $screen-md) {
            align-items: flex-start;
            padding: 0 15px;
        }
    }
    .subtitle, h1 {
        text-align: left;
        max-width: unset;
    }
    @media (max-width: $screen-md) {
        h1 {
            font-size: 30px;
        }
    }
}

.title-underline-textlink-block {
    .main-container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding-bottom: 12px;
        border-bottom: 1px solid $silver;
        @media (max-width: $screen-md) {
            border-bottom: 0;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding: 0 15px;
            box-sizing: border-box;
            line-height: 1.2;
        }
    }
    h1 {
        letter-spacing: -0.7px;
    }
    a {
        font-family: $secondary-font;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.25px;
        color: $black;
        display: flex;
        align-items: center;
        @media (max-width: $screen-md) {
            margin-top: 15px;
        }
        .icon {
            margin-left: 10px;
        }
    }
}

.title-underline-pill-block {
    .main-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 12px;
        border-bottom: 1px solid $silver;
        @media (max-width: $screen-md) {
            border-bottom: 0;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding: 0 15px;
        }
    }
    h1 {
        letter-spacing: -0.7px;
        font-size: 35px;
        max-width: 50%;

        @media (max-width: $screen-md) {
            max-width: 100%;
        }
    }
    a {
        font-family: $secondary-font;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.25px;
        color: $black;
        @media (max-width: $screen-md) {
            margin-top: 15px;
        }
    }
}

.title-with-paragraph {
    @media (max-width: $screen-md) {
        .main-container {
            padding: 0 15px;
            box-sizing: border-box;
        }
    }
    &.pb {
        padding-bottom: 30px;
        @media (max-width: $screen-md) {
            padding-bottom: 25px;
        }
    }
    h1 {
        line-height: 1.18;
        letter-spacing: -0.8px;
        margin-bottom: 25px;
        @media (max-width: $screen-md) {
            font-size: 25px;
        }
    }
    .paragraph {
        font-family: $secondary-font;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.32px;
        color: $black;
    }
}

.title-with-paragraph-half-width {
    .main-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 100px;
        @media (max-width: $screen-md) {
            padding: 0 15px;
            box-sizing: border-box;
            grid-template-columns: 1fr;
            row-gap: 20px;
        }
    }
    &.pb {
        padding-bottom: 30px;
        @media (max-width: $screen-md) {
            padding-bottom: 25px;
        }
    }
    h1 {
        font-size: 30px;
        line-height: 1.33;
        letter-spacing: -0.43px;
        margin-bottom: 20px;
        font-weight: normal;
        @media (max-width: $screen-md) {
            font-size: 22px;
        }
    }
    .paragraph {
        font-family: $secondary-font;
        font-size: 16px;
        line-height: 1.53;
        letter-spacing: -0.2px;
        color: $black;

        ul, ol {
            margin: 1rem 0 1rem 1rem;
            padding: 0;
        }

        ul {
            list-style: disc;
        }
    }
}