.banner-with-description{
    .main-container{
        position: relative;
        .card-container{
            position: absolute;
            max-width: 414px;
            min-height: 237px;
            border-radius: 30px;
            background-color: #478929;
            padding: 20px 30px 30px 30px;
            right: 0;
            top: 50px;
            @media (max-width: $screen-md) {
                position: unset;
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: unset;
                min-height: unset;
        
            }
            h3{
                color: $white;
                margin-bottom: 20px;
                font-size: 32px;
                font-weight: 600;
            }
            p{
                color: $white;
                font-family: 'Nunito', sans-serif;
                font-weight: 200;
                font-size: 17px;
                line-height: 26px;
                letter-spacing: -0.2px
            }
            a{
                display: inline-block;
                font-family: "Nunito";
                font-size: 17px;
                font-weight: 400;
                text-decoration: underline;
                color: $white;
                margin-top: 10px;
                &:visited, &:focus, &:active{
                    text-decoration: none;
                }
            }
        }
        img {
            border-radius: 35px;
            box-shadow: 10px 20px 42px 0 rgba(55, 55, 55, 0.23);
            @media (max-width: $screen-md) {
                box-shadow: unset;
                width: 100%;
                border-radius: 0;
            }
        }
        .image-desktop {
            display: block;
            @media (max-width: $screen-md) {
                display: none;
            }
        }
        .image-mobile {
            display: none;
            @media (max-width: $screen-md) {
                display: block;
            }
        }
    }
    @media (max-width: $screen-md) {
        background-color: #478929;
        padding: 0px 0px 20px 0px;
    }
}