.color-selector {
    min-height: 495px;
    position: relative;
    @media (max-width: $screen-md) {
        min-height: 510px;
        .main-container {
            padding: 0 15px;
            box-sizing: border-box;
            &.small {
                padding: 0;
            }
        }
    }
    .selector-colors-container {
        display: grid;
        grid-template-columns: 200px 1fr;
        column-gap: 80px;
        @media (max-width: $screen-md) {
            grid-template-columns: 1fr;
            row-gap: 20px;
            .colors-container {
                order: 2;
            }
        }
    }
    h1 {
        font-size: 35px;
        margin-bottom: 50px;
        @media (min-width: $screen-md) {
            max-width: 460px;
        }
        @media (max-width: $screen-md) {
            font-size: 30px;
        }
    }
    .colors-container {
        .colors-title {
            font-family: $secondary-font;
            font-size: 20px;
            margin-bottom: 15px;
            @media (max-width: $screen-md) {
                font-size: 18px;
            }
        }
    }
    .slider-360-container {
        overflow: hidden;
        position: relative;
        img {
            width: 100%;
            max-height: 340px;
            object-fit: cover;
        }
        .item-360 {
            min-height: 345px;
            @media (max-width: $screen-md) {
                min-height: 200px;
            }
        }
        .slick-arrow {
            display: block!important;
        }
        .arrow-prev, .arrow-next {
            position: absolute;
            bottom: 50%;
        }
        .arrow-prev {
            left: 0;
        }
        .arrow-next {
            right: 0;
        }
        .icon-360-container {
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 2;
            display: flex;
            justify-content: center;
        }
    }
}