.items-illustration-with-text-block, .items-illustration-with-text-and-description-block {
    .main-container {
        display: flex;
        justify-content: space-between;
        @media (max-width: $screen-md) {
            flex-direction: column;
            padding: 0 15px;
            box-sizing: border-box;
        }
    }
    a {
        color: $black;
        flex-basis: 0;
        flex-grow: 1;
        @media (max-width: $screen-md) {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .small-card {
        transition: 300ms all;
        padding: 40px 20px 30px;
        border-radius: 6px;
        display: flex; 
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media (max-width: $screen-md) {
            flex-direction: row;
            padding: 20px;
            box-shadow: 0 35px 53px 0 rgba(0, 0, 0, 0.06);
            background-color: $white;
            justify-content: flex-start;
            .card-footer {
                display: none;
            }
        }
        img {
            width: 130px;
            @media (max-width: $screen-md) {
                width: 80px;
                max-width: 80px;
            }
        }
        h2 {
            margin-bottom: 15px;
            @media (max-width: $screen-md) {
                font-size: 20px;
                margin-bottom: 8px;
            }
        }
        p {
            font-family: $secondary-font;
            font-size: 16px;
            line-height: 1.31;
            letter-spacing: -0.23px;
            min-height: 85px;
            text-align: center;
            @media (max-width: $screen-md) {
                text-align: left;
                min-height: unset;
                font-size: 14px;
            }
        }
        .card-body {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @media (max-width: $screen-md) {
                padding-left: 30px;
                align-items: flex-start;
                max-width: 70%;
            }

            h3 {
                text-align: center;
                line-height: 1;
                margin-bottom: 8px;
                @media (max-width: $screen-md) {
                    text-align: left;
                    font-size: 20px;
                }
            }
        }
        .card-footer {
            margin-top: 5px;
            opacity: 0;
            transition: 300ms all;
        }
        @media (min-width: $screen-md) {
            &:hover {
                background-color: $white;
                box-shadow: 0 35px 53px 0 rgba(0, 0, 0, 0.06);
                .card-footer {
                    opacity: 1;
                }
            }
        }
    }
} 

.items-illustration-with-text-and-description-block {
    .small-card {
        flex-basis: 0;
        flex-grow: 1;
        @media (max-width: $screen-md) {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .card-body {
            p {
                min-height: 40px;
            }
            .card-footer {
                .description {
                    font-family: $secondary-font;
                    font-size: 16px;
                    line-height: 1.31;
                    text-align: center;
                }
            }
        }
    }
}