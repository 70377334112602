.layout-video-items-container{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 45px;

    .items-content-container{
        display: grid;
        grid-row: 1/2;
        row-gap: 30px;
        .item{
            padding-left: 17px;
            padding-right: 17px;
            .item-header{
                display: flex;
                align-items: center;
                span{
                    margin-left: 13px;
                }
            }
            .item-description{
                margin-top: 10px;
                font-size: 16px;
            }
        }
    }
}

@media (min-width: $screen-md) {
    .layout-video-items-container{
        grid-template-columns: 65% 35%;
        column-gap: 25px;
        .items-content-container{
            grid-column: 2/3;
            display: flex;
            flex-direction: column;
        }
    }
}