.banner-card-elevation {
    .banner-card-elevation-container {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: flex-end;
        @media (min-width: $screen-md) {
            .card-elevation, img {
                border-radius: 6px;
            }
            img {
                max-width: 62%;
            }
        }
        @media (max-width: $screen-md) {
            flex-direction: column;
            img {
                width: 100%;
            }
        }
        .card-elevation {
            left: 0;
            padding: 40px 45px;
            background-color: $white;
            @media (min-width: $screen-md) {
                max-width: 42%;
                position: absolute;
                box-shadow: 10px 35px 50px 0 rgba(0, 0, 0, 0.05);
            }
            @media (max-width: $screen-md) {
                padding: 20px 15px;
                order: 2;
            }
            h2 {
                font-size: 35px;
                margin-bottom: 40px;
                line-height: 40px;
                font-weight: 600;
                @media (max-width: $screen-md) {
                    font-size: 30px;
                    margin-bottom: 25px;
                }
            }
            p {
                font-size: 20px;
                font-family: $secondary-font;
                margin-bottom: 16px;
                @media (max-width: $screen-md) {
                    font-size: 16px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}