.text-with-logos{
    margin-bottom: 50px;
    .main-container{
        p{
            padding: 0px 30px;
            color: #478929;
            font-size: 28px;
            line-height: 30px;
            font-weight: 700;
            text-align: center;
            @media (max-width: $screen-md) {
                text-align: start;
            }
        }
        .container-logos{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 50px;
            row-gap: 20px;
            margin-top: 30px;
            @media (max-width: $screen-md) {
                row-gap: 110px;
            }
        }
    }
}