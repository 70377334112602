.hero-car-showcase {
    a {
        img {
            width: 100%;
        }
        .image-desktop {
            display: block;
            @media (max-width: $screen-md) {
                display: none;
            }
        }
        .image-mobile {
            display: none;
            @media (max-width: $screen-md) {
                display: block;
            }
        }
    }
}