.modal {
    display: none;
    position: fixed;
    z-index: 10000; /* over wagtailuserbar */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background-color: rgba($darker-black, 0.8);
    @media (max-width: $screen-md) {
        overflow: auto;
        .main-container {
            padding: 0 15px;
            box-sizing: border-box;
        }
    }
    .modal-box {
        margin-top: 2rem;
        margin-bottom: 2rem;
        position: relative;
        padding-left: 0;
        padding-right: 0;
        background-color: $white;
        border-radius: 5px;
        .close-button {
            position: absolute;
            top: -36px;
            right: 0;
            z-index: 10;
            cursor: pointer;
            color: $black;
            border: none;
        }
        .modal-header {
            padding: 32px 40px 32px;
            background-color: $bg-bonus;
            border-radius: 5px 5px 0 0;
            @media (max-width: $screen-md) {
                padding: 25px;
            }
            .modal-title {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                @media (max-width: $screen-md) {
                    flex-direction: column;
                }
            }
            h1 {
                font-size: 30px;
                line-height: 1.23;
                margin-bottom: 4px;
                font-weight: normal;
                @media (max-width: $screen-md) {
                    font-size: 25px;
                    order: 2;
                    margin-bottom: 10px;
                }
            }
            small {
                font-size: 12px;
                color: $form-grey;
                line-height: 1.33;
                @media (max-width: $screen-md) {
                    font-size: 15px;
                    margin-bottom: 15px;
                }
            }
            p {
                font-family: $secondary-font;
                font-size: 16px;
                @media (max-width: $screen-md) {
                    font-size: 18px;
                }
            }
        }
        .modal-body {
            background-color: $white;
            max-height: 500px;
            overflow-y: auto;
            padding: 40px;
            border-radius: 0 0 5px 5px;
            min-height: 250px;
            @media (max-width: $screen-md) {
                padding: 20px 25px;
            }
        }
        .modal-footer {
            display: flex;
            justify-content: center;
            background-color: $white;
            padding: 30px 0 40px;
            margin: 0 75px;
            border-radius: 0 0 5px 5px;
            border-top: 1px solid $light-grey;
            @media (max-width: $screen-md) {
                padding: 18px 0 30px;
                margin: 0 15px;
                border-top: none;
            }
        }
    }

    &.external {
        .main-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .modal-box {
            margin: 0;
            .modal-header {
                .modal-title {
                    flex-direction: column;
                }
            }

            .modal-body {
                padding: 0;
                
                .iframe-container {
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    padding-top: 56.25%;
                    
                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
                
            }
        }
    }
    @media(min-height: 850px){
        .main-container{
            display: flex;
            justify-content: center;
            align-items: center;
            .modal-box{
                max-height: 85vh;
                .modal-body {
                    max-height: 56vh;
                    overflow-y:auto;
                }
            }
        }
    }
}

.page-modal {
    align-items: center;
    justify-content: center;
    .main-container {
        max-height: 80vh;
        display: block;
        position: static;
    }
    .modal-box {
        &.prepaid {
            margin-top: 0;
            margin-bottom: 0;
        }
        .modal-header {
            .modal-title {
                flex-direction: column;
            }
        }
        .modal-body {
            &.prepaid {
                overflow-x: auto;
                max-height: 55vh;
            }
        }
    }
}

.modal-accessory-detail {
    display: block;
    overflow: auto;
    .modal-box {
        .modal-header {
            padding: 22px 75px;
            @media (max-width: $screen-md) {
                padding: 18px 15px 30px;
                h1 {
                    font-size: 22px;
                    order: 0;
                }
                p {
                    font-size: 12px;
                }
            }
            .modal-title {
                flex-direction: row;
                .right-title {
                    width: 30%;
                    text-align: right;
                    h1 {
                        margin-bottom: 0;
                    }
                    @media (max-width: $screen-md) {
                        display: none;
                    }
                }
                .left-title {
                    width: 70%;
                }
            }
            small {
                color: $black!important;
            }
        }
        .modal-body {
            display: grid;
            grid-template-columns: 1.42fr 1fr;
            column-gap: 52px;
            padding: 60px 75px 40px;
            overflow-x: hidden;
            @media (max-width: $screen-md) {
                padding: 20px 15px 20px;
                grid-template-columns: 1fr;
                row-gap: 28px;
            }
            .modal-left {
                .description {
                    margin-bottom: 25px;
                    b {
                        font-size: 14px;
                        line-height: 2.5;
                    }
                    p {
                        font-size: 14px;
                        line-height: 1.71;
                    }
                }
                .specs {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: 30px;
                    @media (max-width: $screen-md) {
                        grid-template-columns: 1fr;
                    }
                    .spec-item {
                        font-size: 14px;
                        line-height: 1.64;
                        padding: 10px 0;
                        border-bottom: 1px solid $light-grey;
                        @media (max-width: $screen-md) {
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
            .modal-right {
                .accessory-image {
                    width: 100%;
                    border-radius: 6px;
                    margin-bottom: 22px;
                    @media (max-width: $screen-md) {
                        // margin-left: -15px;
                        width: calc(100% + 30px);
                        border-radius: 0;
                        max-width: 100%;
                    }
                }
                .options {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    @media (max-width: $screen-md) {
                        margin-bottom: 22px;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        .btn {
                            min-width: unset;
                            width: 100%;
                        }
                    }
                    a {
                        color: $black;
                        align-self: flex-end;
                        width: 100%;
                        display: flex;
                        font-size: 14px;
                        line-height: 1.29;
                        max-width: 50%;
                        @media (max-width: $screen-md) {
                            max-width: 55%;
                            margin: 10px auto 0;
                        }
                        img {
                            padding-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

.modal-accessories-list {
    .modal-body {
        display: block!important;
        .categories {
            max-width: 85%;
            margin: 0 auto 12px;
            @media (max-width: $screen-md) {
                max-width: unset;
                width: 100%;
                overflow-y: auto;
                display: flex;
            }
            .btn {
                margin-right: 5px;
                &:last-child {
                    margin-right: none;
                }
            }
        }
        .modal-accessories-container {
            max-width: 85%;
            margin: 0 auto;
            .regular {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                column-gap: 22px;
                row-gap: 50px;
            }
            @media (max-width: $screen-md) {
                display: block;
                margin: 0;
                max-width: unset;
                .accessory-card {
                    margin-right: 20px;
                }
                .slick-list {
                    overflow: visible;
                }
            }
        }
    }
}
.modal-usados-detail{
    display: block;
    overflow: auto;
    .modal-box{
        max-width: 60%;
        border-radius: 50px;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: $screen-md) {
            max-width: 75%;
            margin-top: 1rem;
        }
        @media (max-width: $screen-xs) {
            max-width: 100%;
            margin-top: 1rem;
            margin-bottom: unset;
        }
        .hide{
            display: none;
        }
        .modal-header{
            .modal-title{
                flex-direction: column;
                @media (max-width: $screen-md) {
                    h1{
                        font-size: 20px;
                        order: unset;
                    }
                    p{
                        font-size: 16px;
                    }
                }
            }
        }
        .modal-body{
            padding-top: 20px;
            min-height: unset;
            @media(min-height: 850px){
                max-height: 70vh!important;
                overflow-y:auto;
                .step-three{
                    max-width: 500px;
                }
            }
            .step-one{
                .usados-form{
                    .form-element{
                        text-align:center;
                        input{
                            font-size: 50px;
                            width: 300px;
                            text-align: center;
                            height: unset;
                            color: $black;
                            text-transform: uppercase;
                        }
                    }
                }
                .modal-footer{
                    flex-direction: column;
                    small{
                        text-align: center;
                        margin-top: 10px;
                        font-size: 16px;
                    }
                }
            }
            .step-two{
                .model-summary{
                    .underline-title{
                        margin-top: 12px;
                        .right-element{
                            h2{
                                font-weight: 400;
                            }
                            a{
                                text-decoration: none;
                                &:hover{
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
                .vehicle-data-container{
                    .vehicle-data-form{
                        .custom-dropdown{
                            margin-top: 30px;
                            margin-bottom: 15px;
                            max-width: unset;
                            label{
                                z-index: 0;
                                font-size: 20px;
                                font-weight: 400;
                                @media (max-width: $screen-md) {
                                    font-size: 15px;
                                }
                            }
                            section{
                                ul{
                                    max-height: 130px;
                                    max-width: 350px;
                                    right: 0;
                                }
                            }
                            .selected{
                                .selected-option{
                                    font-size: 20px;
                                    font-weight: 600;
                                    @media (max-width: $screen-md) {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                        .kilometraje-label{
                            margin-top: 0px;
                            padding: 0px;
                            h3{
                                font-weight: 400;
                            }
                            .right-element{
                                .form-element{
                                    margin-top: 0px;
                                    margin-bottom: 0px;
                                    input{
                                        border-bottom: unset;
                                        text-align: end;
                                        font-size: 20px;
                                        font-weight: 600;
                                        color: $black;
                                    }
                                }
                            }
                        }
                        .maintenance-checkbox{
                            display: flex;
                            align-items: center;
                            margin-top: 25px;
                            .checkbox-text{
                                font-family: $secondary-font;
                                font-size: 15px;
                            }
                        }
                    }
                }
                .modal-footer{
                    flex-direction: column;
                    small{
                        text-align: center;
                        margin-top: 10px;
                        font-size: 16px;
                    }
                }
                @media (max-width: $screen-md) {
                    h2{
                        font-size: 20px;
                    }
                }
            }
            .step-three{
                text-align: center;
                small{
                    font-size: 12px;
                    color: $dark-silver;
                    line-height: normal;
                    display: inline-block;
                    font-family: $secondary-font;
                }
            }
        }
        .modal-footer{
            border-top: unset;
            margin-top: 15px;
            .btn{
                &.is-processing{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
            small{
                font-size: 12px;
                color: $red;
            }
        }
    }
}

.modal-financiado {    
    display: block;
    .modal-box {
        .modal-header {
            @media (max-width: $screen-md) {
                h1 {
                    font-size: 22px;
                    order: 0;
                }
                p {
                    font-size: 12px;
                }
            }
            .modal-title {
                flex-direction: column;
            }
        }
        .modal-body {
            .modal-financiado-container {
                .underline-title {
                    position: relative;
                    margin-top: 18px;
                    @media (max-width: $screen-md) {
                        margin-bottom: 55px;
                        padding-bottom: 5px;
                        h3 {
                            font-size: 19px!important;
                        }
                    }
                    .right-element {
                        @media (max-width: $screen-md) {
                            position: absolute;
                            bottom: -35px;
                        }
                        .title-financiado {
                            font-size: 30px;
                            font-weight: normal;
                            @media (max-width: $screen-md) {
                                font-size: 18px;
                            }
                        }
                    }
                }
                .inputs-container{
                    margin-bottom: 20px;
                    .input-range-container { 
                        background-color: $bg-bonus;
                        padding: 20px;
                        min-height: 195px;
                        h2{
                            font-size: 23px;
                        }
                        p{
                            font-size: 15px;
                        }
                        .input-range-body{
                            padding: 0px;
                        }
                        @media (max-width: $screen-md) {
                            margin-bottom: 20px;
                            h2 {
                                font-size: 18px;
                            }
                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }
                .plan-renueve {
                    margin-bottom: 40px;
                    display: none;
                    .underline-title {
                        h3 {
                            font-size: 25px;
                            font-family: $secondary-font;
                            @media (max-width: $screen-md) {
                                font-weight: normal;
                            }
                        }
                        .right-element {
                            h2 {
                                font-size: 30px;
                                @media (max-width: $screen-md) {
                                    font-size: 25px;
                                }
                            }
                        }
                    }
                    .input-renueve {
                        display: flex;
                        margin-top: 10px;
                        span {
                            font-family: $secondary-font;
                            font-size: 16px;
                            letter-spacing: -0.28px;
                            a {
                                color: $darker-black;
                                font-weight: bold;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                        .custom-checkbox {
                            margin-bottom: 0;
                            @media (max-width: $screen-md) {
                                padding-left: 30px;
                            }
                            .checkmark {
                                top: 0;
                                background-color: $light-grey;
                                border: 0;
                                height: 21px;
                                width: 21px;
                                border-radius: 0;
                                @media (max-width: $screen-md) {
                                    top: 3px;
                                }
                                &:after {
                                    left: 7px;
                                    width: 5px;
                                    height: 13px;
                                    border: solid $legals-color;
                                    border-width: 0 2px 2px 0;
                                    transform: rotate(38deg);
                                }
                            }
                            &:hover input ~ .checkmark {
                                background-color: $grey;
                            }
                            input {
                                &:checked ~ .checkmark {
                                    background-color: $grey;
                                }
                            }
                        }
                    }
                    &.show-details{
                        display: block;
                        opacity: 0;
                        animation: fade 1s linear forwards;
                    }
                    @keyframes fade {
                        0% { opacity: 0 }
                        100% { opacity: 1 }
                    }
                }
                .underline-title{
                    &.total-label{
                        padding-top: 10px;
                        border-top: 1px solid #d8d8d8;
                        margin-top: 0px;
                        margin-bottom: 20px;
                        @media (max-width: $screen-md) {
                            align-items: start;
                        }
                        h4{
                            display: flex;
                            align-items: end;
                            column-gap: 15px;
                            font-size: 24px;
                            @media (max-width: $screen-md) {
                                flex-direction: column;
                                align-items: unset;
                            }
                        }
                        .right-element{
                            @media (max-width: $screen-md) {
                                position: unset;
                            }
                        }
                    }
                }
                .summary-financiado {
                    position: relative;
                    min-height: 128px;
                }
                .inputs-container{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    column-gap:20px;
                    width: 100%;
                    @media (max-width: $screen-md) {
                        grid-template-columns: repeat(1, 1fr);
                    }
                    .input-options-container{
                        .input-options-body{
                            display: flex;
                            column-gap:10px;
                            .item-box{
                                width: 50%;
                                display: flex;
                                justify-content: center;
                                span{
                                    font-size: 20px!important;
                                }
                            }
                        }
                    }
                }
                .error-message {
                    color: #b50b1a;
                }
            }
        }
        .modal-footer {
            border-top: none;
        }
    }
}

.modal-dealership-details {
    position: relative;
    right: 0;
    padding: 50px 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    pointer-events: auto;
    @media (max-width: $screen-md) {
        padding: 60px 0;
        .bg-modal {
            background-color: $backdrop;
            width: 100%;
            height: 200%;
            position: absolute;
            top: 0;
        }
    }
    .modal-box {
        max-width: 365px;
        background-color: $white;
        border-radius: 5px;
        padding: 40px;
        height: 100%;
        box-sizing: border-box;
        @media (max-width: $screen-md) {
            max-width: unset;
            position: relative;
            margin: 0 15px;
            padding: 30px 18px 50px;
        }
        @media(min-width:993px){
            height: auto;
            position: absolute;
            right: 0;
            top:54px;
            bottom: 54px;
        }
        .modal-header {
            display: flex;
            flex-direction: column;
            position: relative;
            .modal-close {
                position: absolute;
                top: -30px;
                right: -25px;
                font-size: 20px;
                color: $grey;
                cursor: pointer;
                @media (max-width: $screen-md) {
                    top: -20px;
                    right: 0;
                }
            }
            h3 {
                font-size: 25px;
                font-weight: 600;
                margin: 8px 0 20px;
            }
        }
        .modal-body {
            p {
                font-family: $secondary-font;
                font-size: 16px;
                line-height: 1.44;
                margin: 0;
                display: flex;
                flex-direction: column;
                a {
                    color: $black;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .subtitle {
                font-family: $secondary-font;
                font-size: 14px;
                font-weight: bold;
                line-height: 1.71;
                margin-bottom: 10px;
            }
            .map-options {
                display: flex;
                flex-direction: column;
                padding: 25px 0 5px;
                padding-right: 60px;
                @media (max-width: $screen-md) {
                    padding: 20px 0;
                    align-items: center;
                    a {
                        max-width: 82%;
                    }
                }
                .btn {
                    margin-bottom: 15px;
                    &:last-child {
                        margin-bottom: 10px;
                    }
                }
            }
            hr {
                margin: 20px 0;
                border: none;
                height: 1px;
                background-color: $light-grey;
            }
        }
    }
}

.modal-personal-data{
    display: block;
    .modal-box{
        max-width: 50%;
        border-radius: 50px;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: $screen-md) {
            max-width: 70%;
            margin-top: 1rem;
        }
        @media (max-width: $screen-xs) {
            max-width: 100%;
            margin-top: 1rem;
            margin-bottom: unset;
        }
        .hide{
            display: none;
        }
        .modal-body{
            max-height: 100%!important;
            overflow: hidden;
            padding: 35px 55px;
            border-radius: 5px;
            @media (max-width: $screen-md) {
                padding: 30px 30px;
            }
            form {
                cursor: default;
                margin-top: 15px;
                pointer-events: auto;
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                justify-items: center;
                row-gap: 20px;
                @media (max-width: $screen-md) {
                    row-gap: 10px;
                }
                .form-element {
                    margin: 0;
                    width: 100%;
                    input {
                        border-bottom: 1px solid $form-grey;
                        height: 1.8rem;
                        color: $black;
        
                        &.error {
                            border-color: $red;
                            color: $red;
                        }
                    }
                    input::placeholder{
                        font-size: 14px;
                    }
                }
                .disclaimer{
                    display: flex;
                    .checkbox-tex{
                        font-size: 12px;
                    }
                }
            }
            .buttons{
                margin-top: 25px;
                text-align: center;
                @media (max-width: $screen-xs) {
                    display: grid;
                    row-gap: 15px;
                }
            }
            &.step-one{
                .model-info{
                    text-align: center;
                    img{
                        width: 100%;
                        max-width: 350px;
                    }
                    .title{
                        margin-bottom: 20px;
                    }
                    .instruction{
                        text-align: left;
                        font-size: 16px;
                        span {
                            font-size: 14px;
                            font-weight: bold;
                        }
                    }
                }
                
            }
            &.step-two{
                .dropdowns-container{
                    display: grid;
                    grid-template-columns: 1fr;
                    row-gap: 10px;
                    justify-items: center;
                    .custom-dropdown{
                        margin-top: 30px;
                        margin-bottom: 15px;
                        max-width: unset;
                        label{
                            z-index: 0;
                        }
                        &.disabled{
                            opacity: 0.4;
                            pointer-events: none;
                        }
                        section{
                            ul{
                                max-height: 130px;
                            }
                        }
                    }
                }
                .buttons{
                    margin-top: 70px;
                }
            }
        }
    }
}

.modal-out-of-stock-model{
    display: block;
    .modal-box{
        max-width: 50%;
        border-radius: 50px;
        margin-left: auto;
        margin-right: auto;
        max-height: 90vh!important;
        @media (max-width: $screen-md) {
            max-width: 70%;
            margin-top: 1rem;
        }
        @media (max-width: $screen-xs) {
            max-width: 100%;
            margin-top: 3rem;
            margin-bottom: unset;
        }
        .hide{
            display: none;
        }
        .modal-body{
            max-height: 100%!important;
            overflow: hidden;
            padding: 35px 55px;
            border-radius: 5px;
            @media (max-width: $screen-md) {
                padding: 30px 30px;
            }
            form {
                cursor: default;
                margin-top: 15px;
                margin-bottom: 35px;
                pointer-events: auto;
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                justify-items: center;
                row-gap: 20px;
                @media (max-width: $screen-md) {
                    row-gap: 10px;
                }
                .two-columns{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    -moz-column-gap: 22px;
                    column-gap: 22px;
                    width: 100%;
                }
                .form-element {
                    margin: 0;
                    width: 100%;
                    input {
                        border-bottom: 1px solid $form-grey;
                        height: 1.8rem;
                        color: $black;
        
                        &.error {
                            border-color: $red;
                            color: $red;
                        }
                    }
                    input::placeholder{
                        font-size: 14px;
                    }
                }
            }
            .disclaimer{
                display: flex;
                margin-top: 20px;
                justify-content: center;
                .checkbox-tex{
                    font-size: 12px;
                    a{
                        font-weight: 800
                    }
                }
            }
            .buttons{
                margin-top: 25px;
                text-align: center;
                @media (max-width: $screen-xs) {
                    display: grid;
                    row-gap: 15px;
                }
            }
            .model-info{
                text-align: center;
                img{
                    width: 100%;
                    max-width: 350px;
                }
                .title{
                    margin-bottom: 20px;
                }
                .instruction{
                    text-align: left;
                    font-size: 16px;
                    span {
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
            }
            .dropdowns-container{
                display: grid;
                grid-template-columns: 1fr;
                row-gap: 10px;
                justify-items: center;
                .custom-dropdown{
                    margin-top: 30px;
                    margin-bottom: 15px;
                    max-width: unset;
                    label{
                        z-index: 0;
                    }
                    &.disabled{
                        opacity: 0.4;
                        pointer-events: none;
                    }
                    section{
                        ul{
                            max-height: 130px;
                        }
                    }
                }
            }
            &.final-modal{
                .model-info{
                    .instruction{
                        text-align: center;
                        margin-top:45px;
                    }
                }
                .buttons{
                    margin-top: 50px;
                }
            }
        }
    }
}

.modal-contact-me{
    display: block;
    .modal-box{
        padding: 10px;
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: $screen-md) {
            max-width: 70%;
            margin-top: 1rem;
        }
        @media (max-width: $screen-xs) {
            max-width: 100%;
            margin-top: 1rem;
            margin-bottom: unset;
        }
        .modal-body{
            text-align: center;
            @media (max-width: $screen-md) {
                padding: 30px 30px;
            }
            .buttons-container{
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                row-gap: 15px;
                padding: 0px 70px;
                margin-top: 15px;
                @media (max-width: $screen-md) {
                    padding: 0px 40px;
                }
                a{
                    height: 48px;
                    display: flex;
                    justify-content:center;
                    align-items: center;
                    position: relative;
                    img{
                        max-width: 30px;
                        position: absolute;
                        left: 20px;
                    }
                    &:hover{
                        img{
                            filter: invert(98%) sepia(5%) saturate(331%) hue-rotate(279deg) brightness(118%) contrast(100%);
                        }
                    }
                }
            }
        }
    }
}

.modal-inactivity{
    display: block;
    .modal-box{
        padding: 10px;
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: $screen-md) {
            max-width: 70%;
            margin-top: 1rem;
        }
        @media (max-width: $screen-xs) {
            max-width: 100%;
            margin-top: 1rem;
            margin-bottom: unset;
        }
        .modal-body{
            text-align: center;
            @media (max-width: $screen-md) {
                padding: 30px 30px;
            }
            h3{
                font-size: 30px;
            }
            p{
                font-size: 16px;
                margin: 30px 0px;
                font-weight: 600;
            }
            .buttons-container{
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                row-gap: 15px;
                padding: 0px 70px;
                margin-top: 15px;
                @media (max-width: $screen-md) {
                    padding: 0px 40px;
                }
                a{
                    height: 48px;
                    display: flex;
                    justify-content:center;
                    align-items: center;
                    position: relative;
                }
            }
        }
    }
}

.modal-fleet-certificate {
    display: block;
    overflow: auto;
    .main-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .modal-box {
        .modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 55px;
            max-width: 570px;
            min-height: fit-content;
            > div {
                display: flex;
                flex-direction: column;
                gap: 28px;
                margin-bottom: 50px;
                text-align: center;
                max-width: 460px;
                .modal-title {
                    font-size: 30px;
                    font-weight: 600;
                }
            }
        }
    }
}

#agendamiento\.reservo\.cl {
    .modal-box {
        max-height: 85vh;
        .modal-header {
            .modal-title {
                p {
                    display: none;
                }
            }
        }

        .modal-body {
            height: 65vh;

            .iframe-container {
                padding-top: 0;
                height: 100%;
            }
        }
    }

    @media (min-width: $screen-md) {
        .modal-box {
            max-height: none;
            .modal-header {
                .modal-title {
                    p {
                        display: block;
                    }
                }
            }
        }

        .modal-body {
            height: auto;

            .iframe-container {
                height: auto;
            }
        }

    }
}