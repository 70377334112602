.hero-carousel {
    position: relative;
    // min-height: 464px;
    // @media (max-width: $screen-md) {
    //     min-height: 480px;
    // }
    .carousel-container {
        visibility: hidden;
        .slick-list {
            overflow: visible;
        }
        .carousel-item {
            border-radius: 25px;
            @media (max-width: $screen-md) {
                margin: 0px 8px;
            }
            &.slick-slide {
                box-shadow: 10px 20px 42px 0 rgba(55,55,55,.23);
                transition: 400ms all;
                img {
                    max-width: 85vw;
                    border-radius: 25px;
                }
                @media (min-width: $screen-md) {
                    transform: scale(0.9);
                }
            }
            &.slick-active {
                transform: scale(1);
                transition: 400ms all;
            }
            a {
                .image-desktop {
                    display: block;
                    @media (max-width: $screen-md) {
                        display: none;
                    }
                }
                .image-mobile {
                    display: none;
                    @media (max-width: $screen-md) {
                        display: block;
                    }
                }
            }
        }
    }
}