.legal-cta {
    padding: 80px 0;
    @media (max-width: $screen-md){
        padding: 40px 15px;
    }
    .main-container {
        display: flex;
        justify-content: center;
        a {
            @media (max-width: $screen-md){
                width: 100%;
            } 
        }
    }
}