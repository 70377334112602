//Toyota Type
@font-face {
    font-family: 'ToyotaType';
    src: url('../fonts/ToyotaType/ToyotaType-Light.eot');
    src: url('../fonts/ToyotaType/ToyotaType-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ToyotaType/ToyotaType-Light.woff2') format('woff2'),
        url('../fonts/ToyotaType/ToyotaType-Light.woff') format('woff'),
        url('../fonts/ToyotaType/ToyotaType-Light.ttf') format('truetype'),
        url('../fonts/ToyotaType/ToyotaType-Light.svg#ToyotaType-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ToyotaType';
    src: url('../fonts/ToyotaType/ToyotaType-Regular.eot');
    src: url('../fonts/ToyotaType/ToyotaType-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ToyotaType/ToyotaType-Regular.woff2') format('woff2'),
        url('../fonts/ToyotaType/ToyotaType-Regular.woff') format('woff'),
        url('../fonts/ToyotaType/ToyotaType-Regular.ttf') format('truetype'),
        url('../fonts/ToyotaType/ToyotaType-Regular.svg#ToyotaType-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ToyotaType';
    src: url('../fonts/ToyotaType/ToyotaType-Book.eot');
    src: url('../fonts/ToyotaType/ToyotaType-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ToyotaType/ToyotaType-Book.woff2') format('woff2'),
        url('../fonts/ToyotaType/ToyotaType-Book.woff') format('woff'),
        url('../fonts/ToyotaType/ToyotaType-Book.ttf') format('truetype'),
        url('../fonts/ToyotaType/ToyotaType-Book.svg#ToyotaType-Book') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ToyotaType';
    src: url('../fonts/ToyotaType/ToyotaType-Semibold.eot');
    src: url('../fonts/ToyotaType/ToyotaType-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ToyotaType/ToyotaType-Semibold.woff2') format('woff2'),
        url('../fonts/ToyotaType/ToyotaType-Semibold.woff') format('woff'),
        url('../fonts/ToyotaType/ToyotaType-Semibold.ttf') format('truetype'),
        url('../fonts/ToyotaType/ToyotaType-Semibold.svg#ToyotaType-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ToyotaType';
    src: url('../fonts/ToyotaType/ToyotaType-Bold.eot');
    src: url('../fonts/ToyotaType/ToyotaType-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ToyotaType/ToyotaType-Bold.woff2') format('woff2'),
        url('../fonts/ToyotaType/ToyotaType-Bold.woff') format('woff'),
        url('../fonts/ToyotaType/ToyotaType-Bold.ttf') format('truetype'),
        url('../fonts/ToyotaType/ToyotaType-Bold.svg#ToyotaType-Bold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

//Nunito
@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito/Nunito-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito/Nunito-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito/Nunito-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito/Nunito-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}