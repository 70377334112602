.splitter {
    margin-left: -20px;

    @media (max-width: $screen-md) {
        margin-left: 0;
    }

    .splitter-container {
        background-image: var(--desktop-image);
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 690px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: $screen-md) {
            background-image: var(--mobile-image);
            padding: 50px 0 70px;
            min-height: unset;
            .main-container {
                padding: 0 15px;
            }
        }
        .subtitle {
            font-family: $secondary-font;
            font-size: 16px;
            line-height: 1.44;
            letter-spacing: 0.09px;
            margin-bottom: 20px;
            color: $white;
            @media (max-width: $screen-md) {
                margin-bottom: 5px;
            }
        }
        h2 {
            font-size: 50px;
            line-height: 1.1;
            letter-spacing: -0.72px;
            color: $white;
            max-width: 700px;
            margin: 0 auto 75px;
            @media (max-width: $screen-md) {
                font-size: 30px;
                margin: 0 0 40px;
            }
        }
        @media (min-width: $screen-md) {
            h2, .subtitle {
                text-align: center;
            }
        }
        .splitter-items-container {
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: $screen-md) {
                flex-direction: column;
            }
            a {
                color: $black;
                flex-basis: 0;
                flex-grow: 1;
                margin-right: 20px;
                @media (max-width: $screen-md) {
                    margin-right: 0;
                    margin-bottom: 15px;
                    width: 100%;
                }
                &:last-child {
                    margin-right: 0;
                    @media (max-width: $screen-md) {
                        margin-bottom: 0;
                    }
                }
            }
            .splitter-item {
                padding: 40px;
                border: 1px solid $white;
                border-radius: 10px;
                min-height: 262px;
                transition: 400ms all;
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                box-sizing: border-box;
                position: relative;
                @media (max-width: $screen-md) {
                    min-height: unset;
                    padding: 25px;
                    justify-content: flex-start;
                }
                .floating {
                    position: absolute;
                    right: 40px;
                    bottom: 70px;
                    background-color: $white;
                    width: 14px;
                    height: 8px;
                    @media (max-width: $screen-md) {
                        top: 42px;
                        right: 25px;
                    }
                }
                h3 {
                    min-height: 70px;
                    font-size: 30px;
                    max-width: 270px;
                    line-height: 1.17;
                    letter-spacing: -0.43px;
                    color: $silver;
                    @media (max-width: $screen-md) {
                        font-size: 24px;
                        max-width: 220px;
                        min-height: unset;
                    }
                }
                .item-description {
                    transition: 300ms all;
                    max-width: 380px;
                    @media (min-width: $screen-md) {
                        opacity: 0;
                        max-height: 0;
                        pointer-events: none;
                    }
                    p {
                        font-family: $secondary-font;
                        font-size: 20px;
                        line-height: 1.27;
                        letter-spacing: -0.29px;
                        margin-top: 25px;
                        margin-bottom: 18px;
                        @media (max-width: $screen-md) {
                            font-size: 16px;
                            margin-bottom: 0;
                            margin-top: 10px;
                            color: $white;
                        }
                    }
                    span {
                        font-family: $secondary-font;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 0.81;
                        letter-spacing: 0.09px;
                        .icon {
                            margin-left: 6px;
                            width: 8px;
                            height: 7px;
                        }
                        @media (max-width: $screen-md) {
                            display: none;
                        }
                    }
                }
                @media (min-width: $screen-md) {
                    &:hover {
                        background-color: $white;
                        h3 {
                            color: $black;
                        }
                        .item-description {
                            opacity: 1;
                            pointer-events: default;
                            max-height: 110px;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}