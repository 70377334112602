.maintenance-plan {
    .main-container {
        padding: 75px 0;
        box-sizing: border-box;
        @media (max-width: $screen-md) {
            padding: 40px 15px;
        }
    }
    h1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 28px;
    }
    .model-selector {
        display: flex;
        flex-direction: column;
        align-items: center;
        .selected-model {
            margin-top: 20px;
            margin-bottom: 35px;
            img {
                @media (max-width: $screen-md) {
                    width: 100%;
                }
            }
        }
    }
    .maintenance-plan-list {
        ul {
            li {
                &:before {
                    content: "- ";
                }
            }
        }
    }
    .maintenance-price {
        color: $red;
        font-size: 29px;
        line-height: 0.5;
        display: flex;
        align-items: flex-start;
        @media (max-width: $screen-md) {
            font-size: 22px;
        }
        small {
            font-size: 15px;
            @media (max-width: $screen-md) {
                font-size: 11px;
            }
        }
    }
}