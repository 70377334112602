.zigzag {
    @media (max-width: $screen-md) {
        padding: 0 15px;
    }
    .cols {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        column-gap: 20px;
        @media (max-width: $screen-xs-max) {
            grid-template-columns: 1fr;
            row-gap: 20px;
        }
    }
    .col {
        @media (max-width: $screen-sm-max) {
            flex: 1 1;
        }
    }
    .zigzag-image {
        border-radius: 6px;
        width: 100%;
    }
    .description-wrapper {
        max-width: 68%;
        width: 100%;
        align-self: center;
        justify-self: center;
        text-align: left;
        @media (max-width: $screen-md) {
            max-width: unset;
        }
        .title {
            font-weight: 600;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: -0.29px;
            margin-top: 0;
            margin-bottom: 0;
        }
        .text {
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: -0.29px;
            margin-top: 0;
            margin-bottom: 0;
        }
        a {
            font-size: .8rem;
            display: block;
            color: $black;
            font-weight: bold;
            text-decoration: underline;
            margin-top: 10px;

            i {
                margin-left: 5px;
                position: relative;
                top: 2px;
            }
        }
    }
    &.reverse {
        .cols {
            @media (min-width: $screen-md) {
                .col {
                    order: 1;
                }
                .description-wrapper {
                    order: 0!important;
                }
            }
            
        }
    }
}