.legalblock-container{
    article{
        padding-left: 17px;
        padding-right: 17px;
        .legal-title{
            font-size: 14px;
            font-weight: bold;
        }
        .legal-text{
            font-size: 11px;
            @media (min-width: $screen-md){
                font-size: 12px;
            }
        }
        div{
            margin: 14px 0;
            width: 60px;
            a{
                display: flex;
                align-items: flex-end;
                font-size: 12px;
                color: $red;
                font-weight: bold;
                i{
                    width: 5px!important;
                    background-color: red!important;
                    margin-left: 5px;
                }
            }
        }
    }
    .divider-line{
        width: 100%;
        margin-bottom: 21px;
    }
}