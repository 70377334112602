.carousel-cars {
    padding: 75px 0 45px;
    box-sizing: border-box;
    max-height: 442px;
    height: 100%;
    position: relative;
    @media (max-width: $screen-md) {
        padding: 45px 15px;
        // max-height: 590px;
        max-height: none;
    }
    .main-container {
        position: unset;
    }
    .carousel-car-container {
        visibility: hidden;
        .car-item {
            display: grid;
            grid-template-columns: 0.4fr 1fr auto;
            @media (min-width: $screen-md) {
                padding: 0 15px;
            }
            @media (max-width: $screen-md) {
                grid-template-columns: 1fr;
                row-gap: 30px;
            }
            .specs {
                padding-bottom: 50px;
                @media (max-width: $screen-md) {
                    order: 3;
                    padding-bottom: 0;
                    justify-self: center;
                }
                .title {
                    font-size: 35px;
                    line-height: 1.14;
                    letter-spacing: -0.55px;
                    margin-bottom: 20px;
                    @media (max-width: $screen-md) {
                        margin-bottom: 18px;
                        font-size: 20px;
                        text-align: center;
                    }
                }
                ul {
                    @media (max-width: $screen-md) {
                        margin: 0 auto;
                        max-width: 75%;
                        padding-bottom: 20px;
                    }
                    li {
                        display: flex;
                        margin-bottom: 15px;
                        align-items: center;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        img {
                            width: 100%;
                            max-width: 30px;
                        }
                        span {
                            font-family: $secondary-font;
                            font-size: 14px;
                            font-weight: bold;
                            line-height: 1.43;
                            letter-spacing: 0.08px;
                            margin-left: 15px;
                        }
                    }
                }
            }
            .car-image {
                width: 100%;
                align-self: center;
                @media (max-width: $screen-md) {
                    order: 2;
                }
            }
            .logo {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                @media (max-width: $screen-md) {
                    order: 1;
                    align-items: center;
                }
                img {
                    margin-bottom: 20px;
                }
                .btn {
                    max-width: 152px;
                }
            }
        }
    }
}