.video {
    position: relative;
    @media (min-width: $screen-md) {
        min-height: 680px;
    }
    .bg-container {
        position: absolute;
        width: 100%;
        height: 100%;
        @media (max-width: $screen-md) {
            display: none;
        }
        .bg-top, .bg-bottom {
            height: 50%;
        }
        .bg-bottom {
            background-color: $light-grey;
        }
    }
    .video-container {
        @media (min-width: $screen-md) {
            padding: 85px 0;
        }
        iframe {
            width: 100%;
            @media (min-width: $screen-md) {
                border-radius: 5px;
            }
        }
    }
}