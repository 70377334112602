.header-regular {
    background-image: var(--desktop-image);
    background-size: cover;
    background-position: center;
    min-height: 295px;
    @media (max-width: $screen-md) {
        background-image: var(--mobile-image);
    }
    .main-container {
        display: flex;
        align-items: center;
        min-height: 295px;
        box-sizing: border-box;
        @media (max-width: $screen-md) {
            align-items: flex-end;
            padding: 0 30px;
        }
    }
    h1 {
        font-size: 45px;
        color: $white;
        line-height: 1.11;
        letter-spacing: -0.63px;
        max-width: 400px;
        @media (max-width: $screen-md) {
            font-size: 35px;
            margin-bottom: 65px;
            max-width: unset;
            text-align: center;
        }
    }
    &.header-accessories {
        min-height: 295px;
        .main-container {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            display: flex;
            position: static;
            @media (max-width: $screen-md) {
                align-items: center;
                justify-content: flex-end;
                flex-direction: column;
                padding-top: 40px;
            }
            h1 {
                margin-bottom: 25px;
                color: $black;
            }
            .left-container {
                z-index: 1;
                width: 420px;
                max-width: 100%;
            }
            .header-accessories-image {
                position: absolute;
                right: 0;
                max-width: 100%;
                margin-right: 10vw;
                @media (max-width: $screen-md) {
                    margin-top: 20px;
                    position: relative;
                    margin-right: 0px;;
                }
            }
            .header-accessories-default{
                position: absolute;
                right: 0;
                @media (max-width: $screen-md) {
                    margin-top: 20px;
                    position: relative;
                    right: -30px;
                }
                
            }
        }
        .custom-dropdown {
            &.with-label{
                max-width: 420px;
                label{
                    z-index: 0;
                }
                .selected {
                    border-bottom: 1px solid $grey;
                    .selected-option{
                        max-width: 230px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        @media (max-width: $screen-md) {
                            max-width: 130px;
                        }
                    }
                }
            }
        }
    }
}