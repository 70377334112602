.prepaid-maintenance-plan-selector{
    .item-box {
        padding: 15px 30px;
        min-height: 57px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 2px;
        @media (max-width: $screen-md) {
            padding: 12px 20px;
        }

        .prepaid-maintenance{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .price{
                font-size: 14px;
                font-family: $secondary-font;
                font-weight: 600;
                @media (max-width: $screen-md) {
                    font-size: 13px;
                }
            }
        }
        .save-price{
            color: #EE2527;
            font-size: 15px;
            font-weight: 600;
            font-family: $secondary-font;
            @media (max-width: $screen-md) {
                font-size: 12px;
            }
        }
    } 
}