.complaint-form {
    width: 100vw;
    margin-left: -20px;

    @media (max-width: $screen-md) {
        margin-left: 0;
    }
    
    ::-webkit-calendar-picker-indicator {
        filter: invert(1) brightness(50%);
    }
    &__container {
        position: relative;
        background-image: linear-gradient(189deg, #191919, #000);
        color: white;
        padding: 50px 0 30px;
        @media (max-width: $screen-md) {
            padding-left: 15px;
            padding-right: 15px;
        }
        .content-wrapper {
            margin-bottom: 50px;
            h1 {
                margin-bottom: 14px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
                span {
                    font-size: 14px;
                }
            }
            @media (max-width: $screen-md) {
                h1 {
                    font-size: 35px;
                    margin-bottom: 22px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
        #complaint-form {
            display: flex;
            flex-direction: column;
            @media (max-width: $screen-md) {
                flex-direction: column;
                #terms-error {
                    top: 40px;
                }
            }
            .row-wrapper {
                display: flex;
                justify-content: space-between;
                @media (max-width: $screen-md) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                .disclaimer {
                    font-size: 14px;
                }
            }
            .left-section .form-element,
            .right-section .form-element {
                width: 100%;
                margin-bottom: 50px;
                label {
                    color: $silver;
                    position: absolute;
                    bottom: 0.5rem;
                    left: 0;
                    transition: all 0.4s ease-in-out;
                    font-size: 0.7rem;
                    line-height: 1.2rem;
                    font-weight: 500;
                    pointer-events: none;
                    font-family: $secondary-font;
                }
                input,
                textarea,
                select {
                    &:active,
                    &:focus,
                    &.interacted,
                    &[type="date"] {
                        & i {
                            background-color: red;
                        }
                        & ~ .label {
                            bottom: 1.5rem;
                        }
                    }
                    & ~ .error {
                        top: 1.7rem;
                        color: $red;
                    }
                }
                #complaintDescription-error {
                    top: 4rem;
                }
                textarea {
                    width: 100%;
                    min-width: 100%;
                    height: 4rem;
                    & ~ .label {
                        top: -1.5rem;
                    }
                }
                select {
                    -webkit-appearance: menulist;
                    -moz-appearance: menulist;
                    appearance: menulist;
                }
            }
            .form-element-wrapper {
                display: flex;
                gap: 20px;
                @media (max-width: $screen-md) {
                    flex-direction: column;
                    gap: 0px;
                }
                .form-element {
                    margin-bottom: 10px;
                }
            }
            .left-section,
            .right-section {
                width: 100%;
                max-width: 482px;
                box-sizing: border-box;
                @media (max-width: $screen-md) {
                    max-width: auto;
                }
            }
            .submit-button {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 20px;
                @media (max-width: $screen-md) {
                    align-items: center;
                    .btn {
                        width: 100%;
                    }
                }
            }
            .disclaimer {
                font-family: $secondary-font;
                display: flex;
                font-size: 12px;
                color: $silver;
                line-height: 20px;
                @media (max-width: $screen-md) {
                    margin-top: 50px;
                }
                a {
                    color: $white;
                    text-decoration: none;
                }
                .custom-checkbox {
                    span {
                        margin-left: 0px;
                    }
                }
            }
        }
        .feedback-container {
            width: 50%;
            max-width: 458px;
            margin-left: auto;
            display: flex;
            flex-direction: column;
            @media (max-width: $screen-md) {
                margin-left: 0;
                max-width: 100%;
                width: 100%;
            }
            &__content {
                margin-bottom: 50px;
                h5 {
                    font-size: 40px;
                    line-height: 50px;
                    margin-bottom: 24px;
                }
                p {
                    font-size: 16px;
                }
            }
            &__img {
                display: none;
                @media (max-width: $screen-md) {
                    display: block;
                    max-width: 450px;
                }
            }
            &__cta {
                display: flex;
                justify-content: end;
                a {
                    @media (max-width: $screen-md) {
                        width: 100%;
                    }
                }
            }
        }

        &.submited {
            background: black;
            background-image: url("../images/complait-feedback-bg.png");
            background-repeat: no-repeat;
            min-height: 450px;
            @media (max-width: $screen-md) {
                background-image: none;
            }
        }

        .bg-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: 0.6;
        }
    }
}
