.rich-text {
    padding: 0 15px;
    @media (min-width: $screen-md) {
        padding: initial;
    }

    img.richtext-image.full-width {
        max-width: 100%;
        border-radius: 6px;
        display: block;
        width: auto;
        height: auto;
    }
}