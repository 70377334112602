footer {
    background-image: linear-gradient(189deg,#191919,#000);
    color: $white;
    padding-left: 20px;
    padding-right: 20px;
    @media (max-width: $screen-md) {
        padding-left: 0;
        padding-right: 0;
    }
    .footer-contact {
        .main-container {
            padding-top: 4rem;
            padding-bottom: 5rem;
            @media (max-width: $screen-md) {
                padding-top: 2rem;
                padding-bottom: 8rem;
            }
        }
        small, p {
            font-family: $secondary-font;
            font-size: 16px;
            line-height: 1.44;
            letter-spacing: 0.09px;
            margin: 0;

            a {
                color: white;
                text-decoration: none;
            }
        }
        @media (max-width: $screen-md) {
            p {
                font-size: 14px;
            }
        }
        .content-wrapper {
            @media (max-width: $screen-md) {
                box-sizing: border-box;
            }
            h1 {
                font-size: 2rem;
                font-weight: 600;
                line-height: 1.25;
                letter-spacing: -.6px;
                margin-top: 1rem;
                margin-bottom: 1rem;
                @media (max-width: $screen-md) {
                    margin-top: 0.5rem;
                    font-size: 35px;
                }
            }
        }
        .footer-container {
            display: flex;
            position: relative;
            @media (max-width: $screen-md) {
                flex-direction: column;
                padding: 0 15px;
            }
            .left-section {
                flex: 1 1 50%;
                position: relative;
                box-sizing: border-box;
                @media (max-width: $screen-md) {
                    position: unset;
                }
                @media (min-width: $screen-md) {
                    padding-right: 7rem;
                    border-right: 1px solid $black;
                }
                .footer-social-media {
                    position: absolute;
                    bottom: 0;
                    @media (max-width: $screen-md) {
                        bottom: -135px;
                    }
                    ul {
                        margin-top: 20px;
                        display: flex;
                        li {
                            margin-right: 16px;
                            &:last-child {
                                margin-right: 0;
                            }
                            a {
                                img {
                                    width: 40px;
                                    height: 40px;
                                }
                            }
                        }
                    }
                }
            }
            .right-section {
                box-sizing: border-box;
                flex: 1 1 50%;
                border-right: 0;
                @media (min-width: $screen-md) {
                    padding-left: 5rem;
                }
                .submit-button {
                    display: flex;
                    margin-top: 2rem;
                    justify-content: flex-end;
                    align-items: center;
                    @media (max-width: $screen-md) {
                        justify-content: center;
                        flex-direction: column;
                        .btn {
                            width: 70%;
                        }
                    }
                    .feedback-message {
                        margin-right: auto;
                        font-size: 14px;
                        font-family: $secondary-font;
                        &.success {
                            background: green;
                            color: white;
                            padding: 0.2rem 0.7rem;
                            border-radius: 1rem;
                            font-weight: bold;
                        }
                        &.error {
                            background: $red;
                            color: white;
                            padding: 0.2rem 0.7rem;
                            border-radius: 1rem;
                            font-weight: bold;
                        }
                    }
                }
                .disclaimer {
                    font-family: $secondary-font;
                    display: block;
                    font-size: .6rem;
                    font-weight: 500;
                    color: $grey;
                    line-height: 1rem;
                    margin-top: 2rem;
                    a {
                        color: $white;
                        text-decoration: none;
                    }
                    .text{
                        margin-left: 25px;
                    }
                    @media (max-width: $screen-md) {
                        .custom-checkbox{
                            label{
                                top: 2rem;
                                left: 0;
                            }
                        }
                    }
                }
            }
            .list-options {
                margin: .5rem 0 3rem;
                .item-list {
                    font-size: .7rem;
                    line-height: 1.2rem;
                    font-weight: 500;
                    color: $dark-silver;
                    border-bottom: 1px solid $black;
                    align-items: center;
                    padding: 8px 0;
                    .btn {
                        margin-top: 4px;
                    }
                }
            }
        }
        form {
            position: relative;
            .two-columns {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 22px;
            }
            .error-items {
                display: none;
                font-size: .6rem;
                line-height: 1.2rem;
                color: $red;
                font-weight: 500;
                &.active {
                    display: block;
                }
            }
            .three-columns {
                display: grid;
                grid-template-columns: 0.9fr 0.9fr 0.5fr 26px;
                column-gap: 15px;
                @media (max-width: $screen-md) {
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: 22px;
                }
                .btn {
                    padding: 4px 0;
                    min-width: unset;
                    align-self: center;
                    margin-top: 15px;
                }
            }
            .form-element {
                label {
                    color: $grey;
                    position: absolute;
                    bottom: .5rem;
                    left: 0;
                    transition: all .4s ease-in-out;
                    font-size: .7rem;
                    line-height: 1.2rem;
                    font-weight: 500;
                    pointer-events: none;
                }
                input, textarea, select {
                    &:active,
                    &:focus,
                    &.interacted,
                    &[type="date"] {
                        & ~ .label {
                            bottom: 1.5rem;
                        }
                    }
                    & ~ .error {
                        top: 1.7rem;
                        color: $red;
                    }
                }
            }
        }
        &.flotas, &.test-drive, &.concesionario, &.usados {
            @media (max-width: $screen-md) {
                .main-container {
                    padding-bottom: 2rem;
                }
            }
            .content-wrapper {
                width: 40%;
                @media (max-width: $screen-md) {
                    width: 100%;
                    padding: 0 15px;
                }
            }
            .footer-container {
                .left-section {
                    border-right: 0;
                }
                h2 {
                    margin-top: 50px;
                    letter-spacing: 0.14px;
                }
            }
        }
        &.test-drive, &.flotas, &.usados {
            .footer-container {
                .left-section, .right-section {
                    display: none;
                }
                .spinner-container {
                    display: block;
                }
                &.active {
                    .left-section, .right-section {
                        display: block;
                    }
                    .spinner-container {
                        display: none;
                    }
                }
            }
        }
    }
    .footer-sitemap {
        @media (max-width: $screen-md) {
            display: none;
        }
        ul {
            display: none;
            grid-template-columns: repeat(6, 1fr);
            padding: 50px 0 40px;
            border-top: 1px solid $black;
            overflow: hidden;
            height: 320px;
            li {
                .link-row {
                    font-family: $primary-font;
                    font-size: 12px;
                    line-height: 1.92;
                    letter-spacing: 0.08px;
                    padding-bottom: 10px;
                    color: $silver;
                    margin-top: 45px;
                    display: flex;
                    flex-direction: column;
                    &:first-child {
                        margin-top: 0;
                    }
                    a {
                        color: $silver;
                        @media (min-width: $screen-md) {
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                    .sitemap-links {
                        margin-top: 15px;
                        display: flex;
                        flex-direction: column;
                        a {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
        .sitemap-bottom {
            width: 100%;
            display: flex;
            justify-content: center;
            position: relative;
            top: 2px;
            button {
                border: 1px solid #444;
                border-bottom: none;
                border-radius: 10px 10px 0 0;
                padding: 8px 15px;
                font-family: $secondary-font;
                font-size: 16px;
                letter-spacing: -0.23px;
                outline: none;
                background-color: #000;
                color: $white;
                cursor: pointer;
                display: flex;
                align-items: center;
                .icon {
                    margin-left: 10px;
                    transition: 400ms all;
                }
                &.active {
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .footer-bottom {
        border-top: 2px solid $black;
        @media (max-width: $screen-md) {
            border-top: 1px solid $black;
        }
        .main-container {
            height: 100%;
            display: flex;
            padding: 35px 0;
            justify-content: space-between;
            @media (max-width: $screen-md) {
                flex-direction: column;
                padding: 30px 15px;
                box-sizing: border-box;
            }
        }
        p, a {
            font-family: $secondary-font;
            font-size: .6rem;
            line-height: 1.2rem;
            font-weight: 500;
            color: $silver;
            margin: 0;
        }
        @media (min-width: $screen-md) {
            a {
                transition: 300ms all;
                &:hover {
                    cursor: pointer;
                    color: $white;
                    text-decoration: underline;
                }
            }
            .legal {
                width: 40%;
            }
        }
        .legal {
            @media (max-width: $screen-md) {
                margin-bottom: 20px;
            }
        }
        .footer-links {
            justify-content: space-between;
            @media (max-width: $screen-md) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                row-gap: 20px;
                a {
                    &:nth-child(even) {
                        text-align: right;
                    }
                }
            }
            @media (min-width: $screen-md) {
                width: 60%;
                display: flex;
            }
        }
    }

    #usados-form {
        margin-top: 65px;
    }
    
    #model_other_container {
        display: none;
    }
}

