.sibling-nav-container {
    border-top: 1px solid $silver;
    border-bottom: 1px solid $silver;
    .main-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        // border-bottom: 1px solid $silver;
        @media (max-width: $screen-md) {
            padding: 25px 15px;
            box-sizing: border-box;
        }
    }
    .extra-link-container {
        border: none;
        padding: 10px 15px;
        a {
            font-size: 15px;
            font-weight: bold;
            color: $black;
            &:hover, &.active {
                color: $red;
            }

            .icon {
                position: relative;
                top: 1px;
                margin-left: 5px;
            }
        }
        @media (min-width: 993px) {
            display: none !important;
        }
    }
    nav {
        @media (max-width: $screen-md) {
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 0px;
            }
        }
        ul {
            display: flex;
            li {
                display: flex;
                align-items: center;
                margin-right: 50px;
                @media (max-width: $screen-md) {
                    margin-right: 25px;
                }
                &:last-child {
                    margin-right: 0;
                }
                a {
                    font-size: 14px;
                    letter-spacing: -0.3px;
                    color: $black;
                    transition: color 300ms;
                    &:hover, &.active {
                        color: $red;
                    }
                    @media (max-width: $screen-md) {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    // nav::-webkit-scrollbar {
    //     @media (max-width: $screen-md) {
    //         width: 0 !important;
    //     }
    // }
}