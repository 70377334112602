.outstanding-car {
    padding-left: 10px;
    padding-right: 10px;
}

//Outstanding car block
.car-block-container {
    background-image: var(--bg-image);
    background-position: 0 170px;
    background-size: 100%;
    background-repeat: no-repeat;
    display: grid;
    grid-template-columns: 1fr;
    min-height: 460px;
    @media (min-width: $screen-md) {
        background-position: center;
        grid-template-columns: repeat(2, 1fr);
        background-size: auto 80%;
        min-height: 445px;
    }
    @media (min-width: 678px) and (max-width: 991px) {
        background-position: 50% 45%;
        background-size: 90%;
    }
    @media (max-width: 678px) {
        background-position: 100% 65%;
        background-size: contain;
    }
    .title-container {
        .size-fix {
            @media (min-width: $screen-md) {
                width: 383px;
            }
            h1 {
                font-size: 30px;
                min-height: 150px;
                @media (min-width: $screen-md) {
                    font-size: 35px;
                    line-height: 1.14;
                    letter-spacing: -0.55px;
                }
            }
        }
    }
    .logo-container {
        justify-self: start;
        margin-top: 15px;
        @media (min-width: $screen-md) {
            justify-self: end;
            margin-top: 40px;
        }
        img {
            width: 160px;
            @media (min-width: $screen-md) {
                width: 235px;
            }
        }
        .btn-container {
            display: none;
            @media (min-width: $screen-md) {
                margin-top: 40px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
    .specs-container {
        @media (min-width: $screen-md) {
            margin-top: 0px;
            width: 185px;
        }
        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            @media (min-width: $screen-md) {
                grid-template-columns: repeat(1, 1fr);
            }
            li {
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-top: 20px;
                img {
                    width: 36px;
                    height: 36px;
                }
                span {
                    margin-left: 10px;
                }
            }
        }
    }
    .empty-container{
        grid-row: 3/4;
        min-height: 180px;
        @media (min-width: $screen-md) {
            display: none;
        }
    }
    .button{
        margin-top: 25px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: $screen-md) {
            display: none;
        }
    }
}