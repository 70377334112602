.version-selector {
    min-height: 1025px;
    box-sizing: border-box;
    position: relative;
    @media (max-width: $screen-md) {
        min-height: 940px;
    }
    .title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        h2 {
            width:100%;
        }
        @media (max-width: $screen-md) {
            padding: 0 15px;
            flex-direction: column;
            text-align: center;
            .btn {
                order: 3;
            }
            h1 {
                margin-bottom: 5px;
            }
            h2 {
                margin-bottom: 20px;
            }
        }
    }
    h2 {
        font-weight: normal;
        line-height: 1.48;
        letter-spacing: -0.25px;
    }
    .image-container {
        padding-bottom: 60px;
        display: flex;
        flex-direction: column;
        @media (max-width: $screen-md) {
            padding: 0 15px 40px;
            box-sizing: border-box;
        }
        img {
            margin: -10px auto 0;
            @media (max-width: $screen-md) {
                width: 100%;
                margin: 45px auto 0;
            }
        }
    }
    .version-cards-container {
        background-color: $bg-grey;
        padding: 55px 0;
        position: relative;
        min-height: 560px;
        box-sizing: border-box;
        @media (max-width: $screen-md) {
            padding: 35px 15px;
            min-height: 550px;
            .slick-track {
                padding-bottom: 20px;
            }
        }
        &:before {
            content: "";
            border-right: 25px solid transparent;
            border-bottom: 20px solid $bg-grey;
            border-left: 25px solid transparent;
            position: absolute;
            top: -20px;
            left: 50%;
        }
        .slick-list {
            overflow: visible;
        }
        .slick-slide {
            pointer-events: none;
            height: inherit !important;
            & > div {
                height: 100%;
            }
        }
        .slick-active {
            pointer-events: all;
        }

        .slick-track {
            display: flex !important;
        }

        h2 {
            margin-bottom: 35px;
        }
    }
}

.card-version {
    background-color: $white;
    border-radius: 6px;
    cursor: pointer;
    transition: 400ms box-shadow;
    margin-right: 20px;
    min-height: 480px;
    height: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-end;
    &.active {
        box-shadow: 0 0 0 2px $dark-red;
    }
    @media (min-width: $screen-md) {
        &:hover {
            box-shadow: 0 0 0 2px $dark-red;
        }
    }
    .card-body {
        .card-header {
            padding: 30px 25px 10px;
            .out-of-stock-message{
                font-size: 12px;
                font-family: $secondary-font;
                background-color: #4A4A4A;
                color: $white;
                border-radius: 3px;
                padding: 0px 5px;
                font-weight: 900;
                position: relative;
                top: -15px;
                visibility: hidden;
                &.show{
                    visibility: visible;
                }
            }
            .card-header-content{
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                align-items: baseline;
                h3 {
                    font-weight: 600;
                    line-height: 1.17;
                }
                small {
                    font-family: $secondary-font;
                    font-size: 16px;
                    line-height: 1.69;

                    &.green-tax {
                        font-family: $secondary-font;
                        margin-left: 4px;
                        color: #656566;
                        font-size: 12px;
                    }
                }
                .warning_container {
                    min-height: 40px;
                }
                .version_warning {
                    font-size: 14px;
    
                    &:first-of-type {
                        margin-top: 15px;
                    }
                }
            }
        }
        .bonus {
            padding: 12px 25px;
            background-color: $bg-bonus;
            display: flex;
            align-items: flex-start;

            div {
                margin-left: 13px;
                font-size: 13px;
                font-family: $secondary-font;
                line-height: 1;
                letter-spacing: 0.07px;
                color: $darker-black;

                p {
                    margin-bottom: 0.2em;
                }

                ul {
                    margin: 0;
                    padding: 0;
                    list-style-image: url(../images/icons/tick.svg);
                    list-style-position: outside;
                    margin-left: 12px;
                    display: block;

                    li {
                        font-size: 12px;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
        ul {
            padding: 5px 0;
            display: flex;
            flex-direction: column;
            li {
                padding: 10px 25px;
                border-top: 1px solid $bg-bonus;
                &:first-child {
                    border-top: 0;
                }
                span, strong {
                    font-family: $secondary-font;
                    font-size: 14px;
                    line-height: 1.71;
                }
            }
        }
        .card-disclaimer {
            display: block;
            font-size: 14px;
            padding: 0 25px 30px;
        }
    }
    &.stock-warning{
        cursor: unset;
        background-color: #ededed;
        box-shadow: unset;
        :hover{
            box-shadow: unset;
        }
        .card-body{
            .card-header{
                .out-of-stock-message{
                    width: 100%;
                }
            }
            .bonus{
                background-color: #e3e3e3;
            }
            ul{
                li{
                    border-top: 1px solid $silver;
                    &:first-child {
                        border-top: 0;
                    }
                }
            }
        }
    }
}