.items-photo-triad {
    .main-container {
        display: flex;
        justify-content: space-between;
    }
    .car-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: max-content;
        padding: 20px 0;
        &.hybrid{
            background-color: $light-blue-hybrid;
            border-radius: 12px;
            position: relative;

            .hybrid-logo {
                position: absolute;
                top: 24px;
                left: 24px;
                height: 15px;
                width: auto;
            }

            .btn {
                background-color: $blue-hybrid;
                border-color: $blue-hybrid;

                &:hover {
                    background-color: $light-blue-hybrid;
                    color: $blue-hybrid;
                }
            }
        }
        img {
            margin-bottom: 5px;
            max-width: 300px;
            width: 100%;
            @media (max-width: $screen-md) {
                max-width: 370px;
            }
        }
        h4 {
            letter-spacing: -0.32px;
            text-align: center;
            margin-bottom: 5px;
        }
        small {
            font-family: $secondary-font;
            font-size: 14px;
            text-align: center;
        }

        .green-tax {
            font-family: $secondary-font;
            font-size: 12px;
            font-weight: 500;
            color: #656566;
        }

        .btn {
            margin-top: 20px;

            @media (max-width: $screen-md) {
                margin-top: 10px;
            }
        }

        @media (min-width: $screen-md) {
            &:nth-child(2) {
                img {
                    max-width: 500px;
                }
            }
        }
    }
    .slick-arrow  {
        
    }
}