#color-version-selector-root {
    .selector-colors-container {
        display: grid;
        grid-template-columns: 240px 1fr;
        column-gap: 60px;
        @media (max-width: $screen-md) {
            grid-template-columns: 1fr;
            row-gap: 20px;
            .selects-container {
                order: 2;
            }
        }
    }
    .color-version-selector {
        margin-bottom: 30px;
        &__title {
            margin-bottom: 15px;
            font-size: 20px;
            @media (max-width: $screen-md) {
                font-size: 18px;
            }
        }
    
        .custom-version-select {
            position: relative;
            &__label {
                font-size: 14px;
                padding: 6px 0;
                color: $black;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid $grey;
            }
            &__list {
                display: none;
                position: absolute;
                width: fit-content;
                background-color: #ffffff;
                box-shadow: 2px 8px 20px 0 rgba(0, 0, 0, 0.08);
                box-sizing: border-box;
                padding: 6px 12px;
                transition: all .4s ease-in-out;
                opacity: 1;
                top: calc(100% - 1px);
                z-index: 1;
                max-height: 260px;
                border-radius: 0 0 3px 3px;
                overflow-y:auto !important;

                &::-webkit-scrollbar {
                    width: 15px;
                    appearance: none;
                }
                  
                &::-webkit-scrollbar-thumb {
                    background: #d8d8d8;
                    border-radius: 7.5px;
                    position: absolute;
                    left: -40px;
                    border: 4.5px solid white;
                }

                &.focused {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                }

                li {
                    padding: 12px 0;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    cursor:pointer;
                    color: $darker-black;
                    width: 100%; 

                    &.selected {
                        color: $dark-red;
                    }
                }
            }    
        }
    }

    // .spinner-container {
    //     top: 250%;
    //     @media (max-width: $screen-sm) {
    //         top: 200%;
    //     }
    // }

}
